import { useEffect } from "react";
import RenderInput from "../../../../utils/RenderInput";
import { ecomFieldsDetails } from "../product-fields-modified";
import { formatAttributesToFieldsDataFormat } from "../utils";

const VitalInfoForm = ({ vitalInfoForm, attributes, isHSNCode }) => {
  const { formValues, setFormValues, errors } = vitalInfoForm;
  const vitalFieldDetails = [...ecomFieldsDetails, ...formatAttributesToFieldsDataFormat(attributes)];

  return (
    <div>
      {vitalFieldDetails.map((field) => {
        let returnElement = true;
        let renderElement = (
          <RenderInput
            item={{
              ...field,
              error: errors?.[field?.id] ? true : false,
              helperText: errors?.[field.id] || "",
            }}
            state={formValues}
            stateHandler={setFormValues}
            key={field?.id}
          />
        )
        if(returnElement){
          switch(field.id){
            case "HSNCode":
              if(!isHSNCode){
                renderElement = (
                  <RenderInput
                    item={{
                      ...field,
                      error: errors?.[field?.id] ? true : false,
                      helperText: errors?.[field.id] || "",
                      isDisabled: false
                    }}
                    state={formValues}
                    stateHandler={setFormValues}
                    key={field?.id}
                  />
                )
              }
          }
        }
        return renderElement;
      })}
      <hr className="border-spacing-5" />
    </div>
  );
};

export default VitalInfoForm;