import { useEffect, useMemo, useState } from "react";
import RenderInput from "../../../../utils/RenderInput";
import { Typography, Box, Divider } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import cogoToast from "cogo-toast";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core";
import GemstoneField from "../gemstonefields";
import { _variationFields, gemStone } from "../product-fields-modified";
import { calculateGemstonePrice, calculateSellerPrice, calculateTotalSellingPrice, getProductFieldDetails } from "../utils";

const VariantsInfoForm = ({ formData, fields, index, onFormUpdate, formsErrors, removeForm, generalInfoValues, goldRate, variantsInfoForm }) => {
  const { formValues, setFormValues, errors, setFields } = variantsInfoForm;
  const [showDialog, setShowDialog] = useState(false);
  const [newGemstone, setNewGemstone] = useState({
    gemstoneType: "",
    gemstoneSize: "",
    gemstoneCount: "",
    gemstoneCostPerStone: "",
  });
  const [variantFields, setVariantFields] = useState();
  const [variantFieldDetails, setVariantFieldDetails] = useState();
  const {Purity, makingCharges, wastageOnGold, diamondCostPerCt} = generalInfoValues;

  //update initial variants fields
  useEffect(()=>{
    const variant_fields = _variationFields;
    const variant_field_details = variant_fields.map(fieldId=>getProductFieldDetails(fieldId));
    setVariantFields(variant_fields);
    setVariantFieldDetails(variant_field_details);
    setFields(variant_field_details);
  }, []);

  // useEffect(() => {
  //   setErrors(formsErrors[index]);
  // }, []);

  // useEffect(() => {
  //   setErrors(formsErrors[index]);
  // }, [formsErrors[index]]);

  const gemstonePrice = useMemo(
    () => calculateGemstonePrice(formValues),
    [formValues]
  );
  const totalSellingPrice = useMemo(
    () => calculateTotalSellingPrice({...formValues, Purity, makingCharges, wastageOnGold, diamondCostPerCt}, goldRate),
    [formValues, goldRate]
  );
  const sellerPrice = useMemo(
    () => calculateSellerPrice({...formValues, Purity, makingCharges, wastageOnGold, diamondCostPerCt}, goldRate),
    [formValues, goldRate]
  );

  useEffect(() => {
      setFormValues((prevValues) => ({
        ...prevValues,
        gemstonePrice: gemstonePrice?.toFixed(2),
        totalSellingPrice: totalSellingPrice.toFixed(2),
        sellerPrice: sellerPrice.toFixed(2),
      }));
  }, [gemstonePrice, sellerPrice]);

  const handleGemstoneChange = (updatedGemstone) => {
    // Update the local state for the current gemstone
    setNewGemstone((prevGemstone) => ({
      ...prevGemstone,
      ...updatedGemstone,
    }));
  };

  const handleDialogSubmit = () => {
    // Add the gemstone to the form state only if it has valid values
    if (
      newGemstone.gemstoneType &&
      newGemstone.gemstoneCount &&
      newGemstone.gemstoneCostPerStone
    ) {
      // Update the gemstone values in the gemstone array in the parent component
      setFormValues((prevFormValues) => {
        console.log('prevFormValues', prevFormValues)
        return {
        ...prevFormValues,
        gemstones: [...(prevFormValues.gemstones || []), newGemstone],
      }});

      setNewGemstone({
        gemstoneType: "",
        gemstoneSize: "",
        gemstoneCount: "",
        gemstoneCostPerStone: "",
      });

      setShowDialog(false);
    } else {
      cogoToast.error("Please fill out all the required fields");
    }
  };

  const handleDialogCancel = () => {
    setShowDialog(false);
  };
  const handleGemstoneDelete = (index) => {
    const updatedGemstones = [...formValues.gemstones];
    updatedGemstones.splice(index, 1);
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      gemstones: updatedGemstones,
    }));
  };

  return (
    <div className="mt-5">
      <div className="flex" style={{ justifyContent: "space-between" }}>
        <Typography sx={{ fontWeight: "bold" }}> {"Variation " + (index + 1)}</Typography>
        <button type="button" className="close" aria-label="Close" onClick={() => removeForm(index)}>
          <span aria-hidden="true" className="text-2xl">&times;</span>
        </button>
      </div>
      {variantFieldDetails && variantFieldDetails?.length>0 && variantFieldDetails.map((item) => {
        let renderElement = <RenderInput
          item={{
            ...item,
            error: errors?.[item?.id] ? true : false,
            helperText: errors?.[item.id] || "",
          }}
          state={formValues}
          stateHandler={setFormValues}
          key={item?.id}
        />;
        switch (item.id) {
          case "gemstonePrice":
            renderElement = (
              <>
                <button
                      type="button"
                      // className={
                      //   "border-2 border-[#b47437] px-4 py-2 mt-2 rounded-lg text-[#b47437] font-serif font-bold hover:bg-[#ebe4dc] hover:text-black transition-all"
                      // }
                      className={
                        "border border-black px-3 py-[7px] mt-2 rounded-xl bg-[#ebe4dc]"
                      }
                      onClick={() => setShowDialog(true)}
                    >
                      Add Gemstone
                </button>
                {formValues.gemstones?.length>0 ? <div className="mt-2">
                  <h2 className="font-semibold">Added Gemstones:</h2>
                  <ul>
                    {formValues?.gemstones?.map((gemstone, index) => (
                      <li key={index} className="ml-8">
                      {gemstone && (
                        <>
                          <strong>Type:</strong> {gemstone.gemstoneType}, <strong>Count:</strong> {gemstone.gemstoneCount}, <strong>Cost per Stone:</strong> {gemstone.gemstoneCostPerStone}
                        </>
                      )}
                      <button type="button" onClick={() => handleGemstoneDelete(index)} className="ml-2">
                        <DeleteIcon />
                      </button>
                    </li>
                    ))}
                  </ul>
                </div> : <p className="mt-2 font-serif text-sm text-gray-400 cursor-default">
                    &lt;&lt;&lt;No gemstones added yet. Start by adding your first gemstone!&gt;&gt;&gt;</p>}
                {renderElement}
              </>
            )
            break;
        }
        return renderElement;
      })}
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <GemstoneField
            gemstone={newGemstone}
            onChange={handleGemstoneChange}
            gemstoneFields={gemStone}
          /> 
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel} className={"!text-[#b47437]"}>
            Cancel
          </Button>
          <Button onClick={handleDialogSubmit} className={"!text-[#b47437]"}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Divider className="!my-2"/>
    </div>
  );
};

export default VariantsInfoForm;