import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  MenuItem,
  Button,
  Menu,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tooltip } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import useStyles from "./style";
import CancelOrderModal from "./cancelOrderModal.jsx";
import M2OUpdateModal from "./M2OUpdateModal.jsx";
import { postCall } from "../../../Api/axios.js";

const StyledMoreVertIcon = styled(MoreVertIcon)(({ theme }) => ({
  color: theme.palette.tertiary.main,
}));
//TODO:- This works sometimes only, need to fix it
const StyledTableRow = styled(TableRow)({
  '& > *': {
    borderBottom: '2px solid gray',
  },
});

const isOrderCancellable = (order_state) => {
  return order_state != "Completed" || order_state != "Cancelled";
};

const isItemCustomization = (tags) => {
  let isCustomization = false;
  tags?.forEach((tag) => {
    if (tag.code === "type") {
      tag.list.forEach((listOption) => {
        if (listOption.code === "type" && listOption.value == "customization") {
          isCustomization = true;
          return true;
        }
      });
    }
  });
  return isCustomization;
};

const parseQuoteToGetItems = (orderDetails) => {
  let uuid = 0;
  const provided_by = orderDetails?.provider?.descriptor?.name;
  const breakup = orderDetails?.quote?.breakup;
  const all_items = breakup?.map((break_up_item) => {
    const items = orderDetails.items;
    const itemId = break_up_item["@ondc/org/item_id"];
    const itemIndex = items.findIndex(
      (one) => one.id === break_up_item["@ondc/org/item_id"]
    );
    const item = itemIndex > -1 ? items[itemIndex] : null;
    let itemQuantity = item ? item?.quantity?.count : 0;
    let quantity = break_up_item["@ondc/org/item_quantity"]
      ? break_up_item["@ondc/org/item_quantity"]["count"]
      : 0;
    let textClass = "";
    let quantityMessage = "";
    let net_weight = orderDetails?.makeToOrderItems?.find(
      (m) => m.id === itemId
    )?.net_weight;
    let m2oBasePrice = orderDetails?.makeToOrderItems?.find(
      (m) => m.id === itemId
    )?.item?.price?.value;
    if (quantity === 0) {
      if (break_up_item["@ondc/org/title_type"] === "item") {
        textClass = "text-error";
        quantityMessage = "Out of stock";

        if (itemIndex > -1) {
          items.splice(itemIndex, 1);
        }
      }
    } else if (quantity !== itemQuantity) {
      textClass =
        break_up_item["@ondc/org/title_type"] === "item" ? "text-amber" : "";
      quantityMessage = `Quantity: ${quantity}/${itemQuantity}`;
      if (item) {
        item.quantity.count = quantity;
      }
    } else {
      quantityMessage = `Quantity: ${quantity}`;
    }
    uuid = uuid + 1;
    return {
      id: itemId,
      title: break_up_item?.title,
      title_type: break_up_item["@ondc/org/title_type"],
      isCustomization: isItemCustomization(break_up_item?.item?.tags),
      isDelivery: break_up_item["@ondc/org/title_type"] === "delivery",
      parent_item_id: break_up_item?.item?.parent_item_id,
      price: Number(break_up_item.price?.value)?.toFixed(2),
      basePrice: Number(break_up_item.item?.price?.value).toFixed(2),
      itemQuantity,
      quantity,
      provided_by,
      textClass,
      quantityMessage,
      uuid: uuid,
      net_weight: net_weight,
    };
  });
  let items = {};
  let delivery = {};
  all_items?.forEach((item) => {
    // for type item
    if (item.title_type === "item" && !item.isCustomization) {
      let key = item.parent_item_id || item.id;
      let price = {
        title: item.quantity + " * Base Price",
        value: item.price,
      };
      let prev_item_data = items[key];
      let addition_item_data = {
        title: item.title,
        id: item.id,
        quantity: item.quantity,
        net_weight: item.net_weight,
        price: price,
        basePrice: item.basePrice,
        totalPrice: item.quantity * item.price,
      };
      items[key] = { ...prev_item_data, ...addition_item_data };
    }
    if (item.title_type === "tax" && !item.isCustomization) {
      let key = item.parent_item_id || item.id;
      items[key] = items[key] || {};
      items[key]["tax"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "discount" && !item.isCustomization) {
      let key = item.parent_item_id || item.id;
      items[key] = items[key] || {};
      items[key]["discount"] = {
        title: item.title,
        value: item.price,
      };
    }

    //for customizations
    if (item.title_type === "item" && item.isCustomization) {
      let key = item.parent_item_id;
      items[key]["customizations"] = items[key]["customizations"] || {};
      let existing_data = items[key]["customizations"][item.id] || {};
      let customisation_details = {
        title: item.title,
        price: {
          title: item.quantity + " * Base Price",
          value: item.price,
        },
        quantityMessage: item.quantityMessage,
        textClass: item.textClass,
        quantity: item.quantity,
        cartQuantity: item.cartQuantity,
        totalPrice: item.quantity * item.price,
      };

      items[key]["customizations"][item.id] = {
        ...existing_data,
        ...customisation_details,
      };
    }
    if (item.title_type === "tax" && item.isCustomization) {
      let key = item.parent_item_id;
      items[key]["customizations"] = items[key]["customizations"] || {};
      items[key]["customizations"][item.id] =
        items[key]["customizations"][item.id] || {};
      items[key]["customizations"][item.id]["tax"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "discount" && item.isCustomization) {
      let key = item.parent_item_id;
      items[key]["customizations"] = items[key]["customizations"] || {};
      items[key]["customizations"][item.id] =
        items[key]["customizations"][item.id] || {};
      items[key]["customizations"][item.id]["discount"] = {
        title: item.title,
        value: item.price,
      };
    }
    //for delivery
    if (item.title_type === "delivery") {
      delivery["delivery"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "discount_f") {
      delivery["discount"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "tax_f") {
      delivery["tax"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "packing") {
      delivery["packing"] = {
        title: item.title,
        value: item.price,
      };
    }
    if (item.title_type === "discount") {
      if (item.isCustomization) {
        let id = item.parent_item_id;
      } else {
        let id = item.id;
        items[id]["discount"] = {
          title: item.title,
          value: item.price,
        };
      }
    }
    if (item.title_type === "misc") {
      delivery["misc"] = {
        title: item.title,
        value: item.price,
      };
    }
  });
  return items;
};

const OrderItemsSummaryCard = (props) => {
  const {getOrder, isSuperAdmin, isMakeToOrder, orderItems, order, setOrder, m2oItemsJewelleryType} = props
  const [open, setOpen] = React.useState(false);
  const [itemToCancel, setItemToCancel] = React.useState(null);
//   const [order, setOrder] = React.useState(props?.order);
  const [isM2OUpdateModalOpen, setIsM2OUpdateModalOpen] = useState(false);
  const [prodNetWeights, setProdNetWeights] = useState();
  const [loading, setloading] = useState({
    m2o_modal_loading: false,
  });
  const [itemToEdit, setItemToEdit] = useState(null);
  const classes = useStyles();

  let order_items = [];
  orderItems?.map((item) => {
    order_items.push(item);
  });

  order_items = Object.values(parseQuoteToGetItems(order));

  let cols = [
    { id: "item", align: "left", minWidth: 50, label: "Item" },
    { id: "quantity", align: "center", minWidth: "auto", label: "Qty" },
    { id: "price", align: "center", minWidth: "50", label: "Base Price" },
    {
      id: "state",
      align: "center",
      minWidth: "50",
      label: "Fulfillment Status",
    },
    {
      id: "totalPrice",
      align: "center",
      minWidth: "50",
      label: "Total Amount",
    },
  ];

  if (isMakeToOrder) {
    cols.splice(2, 0, {
      id: "net_weight",
      align: "center",
      minWidth: "50",
      label: "Net Weight",
    });
  }

  if (!isSuperAdmin && ["Pending", "Packed"].includes(order?.fulfillments?.find(f=>f.type==="Delivery")?.state?.descriptor?.code)) {
    cols.push({
      id: "action",
      align: "right",
      minWidth: "50",
      label: "Actions",
    });
  }

//   useEffect(() => {
//     setOrder(props?.order);
//   }, [props?.order]);

  useEffect(()=>{
    if(order)
        getProdNetWeights();
  }, [order]);

  const handleCloseM2OUpdateModal = () => {
    setIsM2OUpdateModalOpen(false);
  };

  const getProdNetWeights = async() => {
    const m2oItems = order?.makeToOrderItems?.map(item=>item.id);
    const nonM2oItems = order?.quote?.breakup?.filter(item=>{
      return item["@ondc/org/title_type"]==="item" && !m2oItems?.includes(item["@ondc/org/item_id"])
    }).map(item=>item["@ondc/org/item_id"]);
    const prodIds = {
      m2oItems,
      nonM2oItems
    }
    const res = await postCall('/api/v1/products/getNetWeights', prodIds);
    setProdNetWeights(res);
  }

  const rows = [
    {
      id: 1,
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPfYkxijCVG7bPceYtABArFY-boMcr12-Fw&usqp=CAU",
      name: "Google Pixel",
      qty: 1,
      price: 10000,
      totalPrice: 10000,
    },
    {
      id: 2,
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPfYkxijCVG7bPceYtABArFY-boMcr12-Fw&usqp=CAU",
      name: "Google Pixel 2",
      qty: 2,
      price: 10000,
      totalPrice: 20000,
    },
    {
      id: 3,
      url: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnPfYkxijCVG7bPceYtABArFY-boMcr12-Fw&usqp=CAU",
      name: "Samsung",
      qty: 1,
      price: 5000,
      totalPrice: 5000,
    },
  ];

  const ThreeDotsMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (e) => {
      setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const { data } = props;

    const handlePartialOrderCancel = (data) => {
      setItemToCancel(data);
    };

    return (
      <>
        <Tooltip title="Action">
          <Button onClick={(e) => handleClick(e)}>
            <StyledMoreVertIcon />
          </Button>
        </Tooltip>
        <Menu
          id="card-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {props?.isMakeToOrderItem && props?.row?.quantity > 0 && (
            <MenuItem
              style={{ padding: 6 }}
              onClick={() => {
                setItemToEdit(props.row.id);
                setIsM2OUpdateModalOpen(true);
              }}
            >
              Edit Net Weight
            </MenuItem>
          )}
          <MenuItem
            style={{ padding: 6 }}
            onClick={() => {
              handlePartialOrderCancel({
                order_id: props.order_id,
                item: props.row,
              });
            }}
          >
            Cancel Order
          </MenuItem>
        </Menu>
      </>
    );
  };

  const renderM2OBadge = () => {
    return (
      <Badge
        badgeContent="Make2Order"
        sx={{
          marginLeft: "2rem",
          marginRight: "0.5rem",
          "& .MuiBadge-badge": { backgroundColor: "purple", color: "white" },
        }}
      ></Badge>
    );
  };

  const renderItem = (item) => {
    const m2oItem = order?.makeToOrderItems?.find((m) => m.id === item.id);
    return cols.map((col, i) => {
      return (
        <TableCell align={col.align} key={i}>
          {col.id == "item" ? (
            <div className="flex flex-row items-center gap-4">
              <p style={{ fontWeight: 600 }}>{item?.title}</p>
              {m2oItem && renderM2OBadge()}
            </div>
          ) : col.id === "state" ? (
            <div>{item?.state}</div>
          ) : col.id === "price" ? (
            <div>
              {item?.basePrice &&
              !Number.isNaN(
                parseFloat(item?.basePrice)
              )
                ? m2oItem?.item?.price?.value
                    ? m2oItem.item.price.value===item.basePrice
                      ? (
                        "₹"+(parseFloat(item.basePrice)).toFixed(2)
                      )
                      : (
                        <>
                        <span className="line-through mr-1 text-slate-500">₹{(parseFloat(item.basePrice)).toFixed(2)}</span>
                        <span>₹{(parseFloat(m2oItem.item.price.value)).toFixed(2)}</span>
                        </>
                      )
                    : "₹"+(parseFloat(item.basePrice)).toFixed(2)
                : "NA"}
            </div>
          ) : col.id === "net_weight" ? (
            <div>
              {
                m2oItem?.net_weight
                ? m2oItem?.net_weight === prodNetWeights?.find(n=>n.id===item.id)?.net_weight
                    ? prodNetWeights?.find(n=>n.id===item.id)?.net_weight
                      ? prodNetWeights?.find(n=>n.id===item.id)?.net_weight+"g"
                      : "NA"
                    : (
                        <>
                            <span className="line-through mr-1 text-slate-500">
                                {prodNetWeights?.find(n=>n.id===item.id)?.net_weight}g
                            </span>
                            <span>
                                {m2oItem?.net_weight}g
                            </span>
                        </>
                    )
                : prodNetWeights?.find(n=>n.id===item.id)?.net_weight
                  ? prodNetWeights?.find(n=>n.id===item.id)?.net_weight+"g"
                  : "NA"
              }
            </div>
          ) : col.id === "action" ? (
            <div style={{ cursor: "pointer" }}>
              {isOrderCancellable(order?.state) &&
              item?.state !== "Cancelled" ? (
                <ThreeDotsMenu
                  order_uuid={order?._id}
                  order_id={order?.orderId}
                  row={item}
                  getOrder={getOrder}
                  isMakeToOrderItem={order?.makeToOrderItems?.find(
                    (m) => m.id === item.id && m2oItemsJewelleryType[item.id]!=="fashion"
                  )}
                />
              ) : (
                // props?.order?.state
                <></>
              )}
            </div>
          ) : col.id === "totalPrice" ? (
            <div>
                {m2oItem?.price?.value
                  ? m2oItem.price.value===item.price?.value
                    ? (
                      <span>₹{parseFloat(item.price?.value)}</span>
                    )
                    : (
                      <>
                        <span className="line-through mr-1 text-slate-500">₹{parseFloat(item.price?.value)}</span>
                        <span>₹{parseFloat(m2oItem?.price?.value)}</span>
                    </>
                    ) : (
                    <span>₹{parseFloat(item.price?.value)}</span>
                )}
            </div>
          ) : col.id === "quantity" ? (
            <span>{m2oItem?.quantity || item?.quantity}</span>
          ) : null}
        </TableCell>
      );
    });
  };

  const renderItemDetails = ({quote, qIndex, isCustomization, order}) => {
    if(order?.makeToOrderItems?.length>0){
      let m2oItem = order?.makeToOrderItems?.find(m=>m.id===quote.id);
      if(m2oItem && m2oItem.price?.value){
        quote.price = {
          title: m2oItem.quantity + " * Base Price",
          value: m2oItem.price.value
        }
      }
    }
    return (
      <div>
        <div
          className={classes.summaryQuoteItemContainer}
          key={`quote-${qIndex}-price`}
        >
          <Typography
            variant="body1"
            className={
              isCustomization
                ? classes.summaryCustomizationPriceLabel
                : classes.summaryItemPriceLabel
            }
          >
            {quote?.price?.title}
          </Typography>
          <Typography
            variant="body1"
            className={
              isCustomization
                ? classes.summaryCustomizationPriceValue
                : classes.summaryItemPriceValue
            }
          >
            {`₹${quote?.price?.value}`}
          </Typography>
        </div>
        {quote?.tax && (
          <div
            className={classes.summaryQuoteItemContainer}
            key={`quote-${qIndex}-tax`}
          >
            <Typography
              variant="body1"
              className={
                isCustomization
                  ? classes.summaryCustomizationTaxLabel
                  : classes.summaryItemTaxLabel
              }
            >
              {quote?.tax.title}
            </Typography>
            <Typography
              variant="body1"
              className={
                isCustomization
                  ? classes.summaryCustomizationPriceValue
                  : classes.summaryItemPriceValue
              }
            >
              {`₹${quote?.tax.value}`}
            </Typography>
          </div>
        )}
        {quote?.discount && (
          <div
            className={classes.summaryQuoteItemContainer}
            key={`quote-${qIndex}-discount`}
          >
            <Typography
              variant="body1"
              className={
                isCustomization
                  ? classes.summaryCustomizationDiscountLabel
                  : classes.summaryItemDiscountLabel
              }
            >
              {quote?.discount.title}
            </Typography>
            <Typography
              variant="body1"
              className={classes.summaryItemPriceValue}
            >
              {`₹${quote?.discount.value}`}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  const renderItemAllDetails = (quote, qIndex) => {
    return (
      <div key={`quote-${qIndex}`}>
        <div
          className={classes.summaryQuoteItemContainer}
          key={`quote-${qIndex}-title`}
        >
          <Typography
            variant="body1"
            className={`${classes.summaryItemLabel} ${quote.textClass}`}
          >
            {/* {quote?.title} */}
            <p className={`${classes.ordered_from} ${quote.textClass}`}>
              {quote.quantityMessage || ''}
            </p>
          </Typography>
        </div>
        {renderItemDetails({quote: JSON.parse(JSON.stringify(quote)), order})}
        {quote?.customizations && (
          <div key={`quote-${qIndex}-customizations`}>
            <div
              className={classes.summaryQuoteItemContainer}
              key={`quote-${qIndex}-customizations`}
            >
              <Typography
                variant="body1"
                className={classes.summaryItemPriceLabel}
              >
                Customizations
              </Typography>
            </div>
            {Object.values(quote?.customizations).map(
              (customization, cIndex) => (
                <div key={cIndex}>
                  <div
                    className={classes.summaryQuoteItemContainer}
                    key={`quote-${qIndex}-customizations-${cIndex}`}
                  >
                    <Typography
                      variant="body1"
                      className={classes.summaryCustomizationLabel}
                    >
                      {customization.title}
                    </Typography>
                  </div>
                  {renderItemDetails(customization, cIndex, true)}
                </div>
              )
            )}
          </div>
        )}
      </div>
    );
  };

  const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
      <>
        <StyledTableRow {...otherProps}>
          <TableCell padding="checkbox">
            <IconButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          {children}
        </StyledTableRow>
        {isExpanded && (
          <TableRow>
            <TableCell padding="checkbox" />
            {expandComponent}
          </TableRow>
        )}
      </>
    );
  };

  return (
    <div>
      <div>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {cols.map((col) => (
                  <TableCell
                    key={col.id}
                    align={col.align}
                    style={{
                      minWidth: col.minWidth,
                      fontWeight: 600,
                      fontSize: 16,
                    }}
                  >
                    {col.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {order_items?.map((item, i) => {
                let product = item?.details;
                return (
                    <ExpandableTableRow
                      key={item.order_id}
                      expandComponent={
                        <TableCell colSpan="4">
                          {renderItemAllDetails(item)}
                        </TableCell>
                      }
                    >
                      {renderItem(item)}
                    </ExpandableTableRow>
                );
              })}

              <TableRow>
                <TableCell
                  sx={{ borderBottom: "unset" }}
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={7}
                >
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        History
                      </Typography>
                      <Table size="small" aria-label="purchases">
                        <TableHead>
                          <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Customer</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Total price ($)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((historyRow) => (
                            <TableRow key={historyRow.date}>
                              <TableCell component="th" scope="row">
                                {historyRow.date}
                              </TableCell>
                              <TableCell>{historyRow.customerId}</TableCell>
                              <TableCell align="right">
                                {historyRow.amount}
                              </TableCell>
                              <TableCell align="right">100</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <CancelOrderModal
        showModal={itemToCancel ? true : false}
        setOrder={setOrder}
        handleCloseModal={() => setItemToCancel(null)}
        data={itemToCancel}
      />
      <M2OUpdateModal
        showModal={isM2OUpdateModalOpen}
        handleCloseModal={() => handleCloseM2OUpdateModal()}
        order={order}
        loading={loading}
        setloading={setloading}
        rowItemId={itemToEdit}
        getOrder={getOrder}
      />
    </div>
  );
};

export default OrderItemsSummaryCard;
