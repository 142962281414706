import { allProductFieldDetails, ecomFieldsDetails } from "./product-fields-modified";
import { isAmountValid } from "../../../utils/validations";
import {  MAX_STRING_LENGTH_50 } from "../../../utils/constants";

export const getFormErrors = (fields, formValues) => {
  if (formValues) {
    let form_errors = {};
    let error = "";
    fields.forEach((field) => {
      if(!field) return;
      let id = field.id;
      let field_value = formValues[id];
      if (!field.required) {
        error = "";
      } else if (field.type === "number") {
        error = !field_value
          ? "Please enter a valid number"
          : !isAmountValid(field_value)
          ? "Please enter only digit"
          : "";
      } else if (field.type === "upload") {
        error = field_value.length < 2 ? "Minimum 2 images are required" : "";
      } else {
        error =
          field_value?.trim() === ""
            ? id + " is required"
            : field_value?.length > MAX_STRING_LENGTH_50
            ? `Cannot be more than ${MAX_STRING_LENGTH_50} characters`
            : "";
      }
      form_errors[id] = error;
    });
    return form_errors;
  } else {
    return {};
  }
};

export const getProductFieldDetails = (fieldId) => {
  return allProductFieldDetails.find((field) => field.id === fieldId);
};

export const categoryInitialValues = {
  productCategory: "",
  productSubcategory1: "",
  jewelleryCategory: "",
  jewelleryType: "",
};

export const productInfoInitialValues = (isEdit, jewelleryType) => {
  const initialValues = {
    productCode: "",
    productName: "",
    GST_Percentage: 3,
    quantity: 1,
    QCStatus: "In Progress",
    // QCDesc: "In Progress",
    // huidNumber: "",
    huidDone: "",
    hallmarkDone: "",
    Finishing: "",
    weight: "",
    wastageOnGold: "",
    net_weight: "",
    diamonds_weight: "",
    diamondColour: "",
    diamondClarity: "",
    Purity: "",
    totalSellingPrice: "",
    sellerPrice: "",
    sellerBasePrice: "",
    makeToOrder: "false",
    makeToOrderMaxPrice: "",
    makeToOrderMaxSellingPrice: "",
    diamondCostPerCt: "",
    pricingType: "",
    makingChargesType: "",
    makingCharges: "",
    description: "",
    images: [],
    certifications: [],
    gemstones: [],
    gemstonePrice: "",
    // fulfillmentOption: "",
    // dispatchTime: "2",
    // isCancellable: "false",
    // isReturnable: "false",
    // returnWindow: "7",
    // countryOfOrigin: "IND",
    // manufacturerOrPackerName: "",
    // manufacturerOrPackerAddress: "",
    // HSNCode: "7113",
  };
  if(isEdit){
    initialValues["makeToOrder"] = ""
  }
  if(["silver"].includes(jewelleryType)){
    initialValues["pricingType"] = "net_weight";
    initialValues["makingChargesType"] = "amount";
  }
  return initialValues;
};

export const deliveryInfoInitialValues = () => {
  const initialValues = {
    fulfillmentOption: "",
    dispatchTime: "2",
    returnWindow: "7",
    isCancellable: "",
    isReturnable: "",
    makeToOrderDispatchTime: "",
  };
  return initialValues;
}

export const vitalInfoInitialValues = (attributes, subCategory, jewelleryType) => {
  const vitalFieldDetails = [...ecomFieldsDetails, ...formatAttributesToFieldsDataFormat(attributes)];
  return vitalFieldDetails.reduce((acc, field)=>{
    switch(field.id){
      case "countryOfOrigin":
        acc[field.id] = "IND";
        break;
      case "HSNCode":
        if(jewelleryType === "gold"){
          switch(subCategory){
            case "Gold Coins":
              acc[field.id] = "7118";
              break;
            case "Gold Articles":
              acc[field.id] = "7114";
              break;
            default:
              acc[field.id] = "7113";
              break;
          }
        } else if(jewelleryType === "diamond"){
          acc[field.id] = "7113";
        } else if(jewelleryType === "silver"){
          switch(subCategory){
            case "Silver Articles":
              acc[field.id] = "7114";
              break;
            case "Silver Coins and Bars":
              acc[field.id] = "7118";
              break;
            default:
              acc[field.id] = "7113";
              break;
          }
        } else if(jewelleryType === "fashion"){
          acc[field.id] = "7117";
        } else{
          acc[field.id] = "";
        }
        break;
      case "Size":
        acc[field.id] = subCategory === "Rings" ? "" : "Free Size";
        break;
      default:
        acc[field.id] = "";
        break;
    }
    return acc;
  }, {})
}

const extractUpdatedValues = ({updatedValues, goldRate, jewelleryType, silverRate}) => {
  const diamondCostPerCt = parseFloat(updatedValues.diamondCostPerCt) || 0;
  const makingChargesType = updatedValues.makingChargesType || "amount";
  const makingCharges = parseFloat(updatedValues.makingCharges) || 0;
  const gemstonePrice = parseFloat(updatedValues.gemstonePrice) || 0;
  const diamonds_weight = parseFloat(updatedValues.diamonds_weight) || 0;
  const wastageOnGold = parseFloat(updatedValues.wastageOnGold) || 0;
  const gstMultiplier = (1 + parseFloat(updatedValues.GST_Percentage) / 100);
  const Purity =
    jewelleryType === "silver"
      ? updatedValues.Purity || 92.5
      : updatedValues.Purity || 22;
  const GoldPrice = (goldRate * Purity) / 24;
  const SilverPrice = (silverRate * Purity) / 100.0;
  return { diamondCostPerCt, makingCharges, makingChargesType, gemstonePrice, diamonds_weight, wastageOnGold, gstMultiplier, Purity, GoldPrice, SilverPrice };
}

export const calculateTotalPrice = ({updatedValues, goldRate, makeToOrder, fieldIdentifier}) => {
  const { diamondCostPerCt, makingCharges, gemstonePrice, diamonds_weight, wastageOnGold, gstMultiplier, GoldPrice } = extractUpdatedValues({updatedValues, goldRate});
  const netWeight =
    makeToOrder
      ? parseFloat(updatedValues.net_weight_range?.maxVal)
      : parseFloat(updatedValues.net_weight);
  const totalMakingCost = makingCharges * netWeight;
  if(fieldIdentifier === "selling_price"){
    const goldCostWithCommission = netWeight * (1 + (wastageOnGold + 2) / 100) * GoldPrice;
    const diamondCostWithCommission = (diamondCostPerCt * 1.02) * diamonds_weight;
    const totalMaterialCostWithCommission = (goldCostWithCommission + diamondCostWithCommission);
    const updatedTotalSellingPrice = (totalMaterialCostWithCommission + totalMakingCost + gemstonePrice) * gstMultiplier;
    return updatedTotalSellingPrice;
  }
  if(fieldIdentifier === "seller_price"){
    const goldCostWithoutCommission = netWeight * (1 + wastageOnGold/100) * GoldPrice;
    const diamondCostWithoutCommission = diamondCostPerCt * diamonds_weight;
    const totalMaterialCostWithoutCommission = (goldCostWithoutCommission + diamondCostWithoutCommission) * gstMultiplier;
    const updatedSellerPrice = totalMaterialCostWithoutCommission + totalMakingCost + gemstonePrice;
    return updatedSellerPrice;
  }
}

export const calculateTotalPriceForSilver = ({updatedValues, silverRate, makeToOrder, sellerPrice, makeToOrderMaxPrice, fieldIdentifier }) => {
  const { makingCharges, makingChargesType, gemstonePrice, gstMultiplier, SilverPrice } = extractUpdatedValues({updatedValues, silverRate});
  const netWeight =
    makeToOrder
      ? parseFloat(updatedValues.net_weight_range?.maxVal)
      : parseFloat(updatedValues.net_weight);
  if(fieldIdentifier === "selling_price"){
    const updatedTotalSellingPrice =
      makeToOrder
        ? 1.0354 * makeToOrderMaxPrice
        : 1.0354 * sellerPrice;
    return updatedTotalSellingPrice;
  }
  if(fieldIdentifier === "seller_price"){
    let updatedSellerPrice = 0;
    const totalMaterialCost = SilverPrice * netWeight;
    if(makingChargesType === "amount"){
      const totalMakingCost = makingCharges * netWeight;
      updatedSellerPrice = totalMaterialCost + totalMakingCost + gemstonePrice;
    } else{
      const totalMakingCost = (makingCharges/100) * netWeight * SilverPrice;
      updatedSellerPrice = totalMaterialCost + totalMakingCost + gemstonePrice;
    }
    //add gst
    updatedSellerPrice *= gstMultiplier;
    return updatedSellerPrice;
  }
}

export const calculateTotalSellingPrice = ({updatedValues, goldRate, silverRate, jewelleryType, sellerPrice, makeToOrderMaxPrice, makeToOrder}) => {
  let totalSellingPrice = 0;
  if(jewelleryType === "silver" || jewelleryType === "fashion"){
    totalSellingPrice = calculateTotalPriceForSilver({updatedValues, silverRate, makeToOrder, sellerPrice, makeToOrderMaxPrice, fieldIdentifier:'selling_price'});
  } else{
    totalSellingPrice = calculateTotalPrice({updatedValues, goldRate, makeToOrder, fieldIdentifier:'selling_price'});
  }
  console.log(`${makeToOrder ? 'makeToOrderMaxSellingPrice' : 'totalSellingPrice'}->`, totalSellingPrice);
  return totalSellingPrice;
};

export const calculateSellerPrice = ({updatedValues, goldRate, silverRate, jewelleryType, pricingType, makeToOrder}) => {
  let sellerPrice = 0;
  const sellerBasePrice = parseFloat(updatedValues.sellerBasePrice) || 0;
  const gstMultiplier = (1 + parseFloat(updatedValues.GST_Percentage) / 100);
  if(jewelleryType === "silver"){
    if(pricingType === "net_weight"){
      sellerPrice = calculateTotalPriceForSilver({updatedValues, silverRate, makeToOrder, fieldIdentifier:'seller_price'});
    } else{
      sellerPrice = sellerBasePrice * gstMultiplier;
    }
  } else if(jewelleryType === "fashion"){
    sellerPrice = sellerBasePrice * gstMultiplier;
  } else{
    sellerPrice = calculateTotalPrice({updatedValues, goldRate, makeToOrder, fieldIdentifier:'seller_price'});
  }
  console.log(`${makeToOrder ? 'makeToOrderMaxPrice' : 'sellerPrice'}->`, sellerPrice);
  return sellerPrice;
};

export const calculateGemstonePrice = (updatedValues) => {
  return updatedValues?.gemstones?.reduce((total, gemstone) => {
    return (
      total +
      parseFloat(gemstone.gemstoneCostPerStone) *
      parseInt(gemstone.gemstoneCount)
    );
  }, 0);
};

export const formattedVariantDataForAddProduct = (variantForms, variationOn, selectedVariantNames) => {
  let variant_forms_data = [...variantForms];

  return variant_forms_data.map((variantData) => {
    if (variationOn === "attributes") {
      let variant_attrs = selectedVariantNames.reduce((acc, variant_name) => {
        acc[variant_name] = variantData[variant_name];
        delete variantData[variant_name];
        return acc;
      }, {});
      variantData["varientAttributes"] = variant_attrs;
    }
    delete variantData["formKey"];
    delete variantData["uploaded_urls"];
    return variantData;
  });
};

export const formatAttributesToFieldsDataFormat = (variants, required = false) => {
    return variants?.map((variant) => {
      return {
        id: variant.name,
        title: variant.name,
        placeholder: "Example, " + variant.example,
        type: variant.type || "input",
        required: variant.required || required,
        options: variant.type === "select" ? variant.options : null,
        file_type: variant.type === "upload" ? "product_image" : null,
      };
    });
  };

export const hsn_gst_mapping = {
  "7113" : 3,
  "7114" : 3,
  "7117" : 3,
  "7118" : 3
}