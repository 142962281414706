import {
  Button,
  Modal,
  CircularProgress,
  useTheme,
  TextField,
} from "@mui/material";
import { putCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import { useEffect, useState } from "react";

const M2OUpdateModal = (props) => {
  const {
    showModal,
    handleCloseModal,
    loading,
    setloading,
    order,
    rowItemId,
    getOrder
  } = props;
  const [m2oItems, setM2oItems] = useState([]);
  const [fashionItems, setFashionItems] = useState([]);
  const theme = useTheme();

  useEffect(()=>{
    if(order){
      setM2oItems(JSON.parse(JSON.stringify(order?.makeToOrderItems || [])));
      setFashionItems(order?.additionalFields?.filter(f=>f.jewelleryType==="fashion").map(f=>f.itemId));
    }
  }, [order])

  const handleM2OUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formValues = Object.fromEntries(formData.entries());
    if(Object.values(formValues).some(v=>v.trim()=="")){
      cogoToast.warn('Please provide net_weight for make to order item(s) first!');
      return;
    }
    try {
      setloading((prev_state)=>({
        ...prev_state,
        m2o_modal_loading: true
      }));
      const m2oUpdateUrl = `/api/v1/orders/${order.orderId}/m2oUpdate`;
      const updatedItems = await putCall(m2oUpdateUrl, m2oItems);
      cogoToast.success("Net Weight(s) Updated Successfully!");
      await getOrder();
      handleCloseModal(true);
      setM2oItems(updatedItems);
      setloading((prev_state)=>(
        {
          ...prev_state,
          m2o_modal_loading: false
        }
      ));
    } catch (error) {
      console.log('err->', error);
      cogoToast.error('Something Went Wrong!');
      setloading((prev_state)=>({
        ...prev_state,
        m2o_modal_loading: false
      }));
    }
  };

  const getProdTitle = (itemId) => {
    const prod_title = order?.quote?.breakup
    ?.filter((b) => b["@ondc/org/title_type"] === "item")
    ?.find((i) => i["@ondc/org/item_id"] === itemId)?.title;
    if(!prod_title)
      cogoToast.error('Unable to fetch product title');
    return prod_title;
  }

  const renderInput = (id, index) => {
    return (
      <TextField
        size="small"
        type="number"
        placeholder="Net Weight (g)"
        id={`item-${id}`}
        name={`net_weight-${index}`}
        value={m2oItems?.find(m=>m.id===id)?.net_weight || ""}
        onChange={(e) => {
          const newM2oItems = m2oItems.map(m2=>{
            let value = e.target.value;
            const [integerPart, decimalPart] = value.split('.');
            const limitedIntegerPart = integerPart.slice(0, 3);
            const limitedDecimalPart = (decimalPart || "").slice(0, 3);
            value = limitedIntegerPart + (decimalPart ? '.' + limitedDecimalPart : '');
            if(m2.id===id)
              m2.net_weight = value;
            return m2;
          });
          setM2oItems(newM2oItems);
        }}
        sx={{
          maxWidth: "10rem",
        }}
      />
    );
  }

  if(m2oItems){
    return (
      <Modal
        open={showModal}
        onClose={() => {
          handleCloseModal();
        }}
        width={100}
      >
        <form
          onSubmit={handleM2OUpdate}
          noValidate
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "24px 40px",
            borderRadius: 20,
          }}
        >
          <p
            className="font-semibold text-xl text-center"
            style={{ marginBottom: 8 }}
          >
            Update Final Net Weight
          </p>
          <p className="w-96 mb-2">
            {rowItemId ? "Please update the final net weight for this make-to-order item:"
            : "Please update the final net weight for these make-to-order item(s):"}
          </p>
          { rowItemId ? (
            <div className="flex flex-row items-center justify-between">
              <label htmlFor={`item-${rowItemId}`} className="text-center">
                {getProdTitle(rowItemId)}
              </label>
              {renderInput(rowItemId, 0)}
          </div>
          ) : (
            m2oItems?.filter(m=>!fashionItems.includes(m.id)).map((m, i) => {
              const m2oItemId = m.id;
              if(order?.items?.find(item=>item.id===m2oItemId)?.quantity?.count>1){
                //TODO: allow different net_weight for multiple items
              }
              return (
                <div className="flex flex-row items-center justify-between mb-2" key={i}>
                  <label htmlFor={`item-${i}`} className="text-center">
                    {getProdTitle(m2oItemId)}
                  </label>
                  {renderInput(m2oItemId, i)}
                </div>
              );
            })
          )}
          <div className="flex justify-end mt-4">
            <Button
              type="submit"
              variant="outlined"
              color="tertiary"
              disabled={loading.m2o_modal_loading}
            >
              {loading.m2o_modal_loading ? (
                <>
                  {rowItemId ? "Confirm":"Ready To Ship"}&nbsp;&nbsp;
                  <CircularProgress
                    size={18}
                    sx={{ color: theme.palette.tertiary.main }}
                  />
                </>
              ) : (
                <span>{rowItemId ? "Confirm":"Ready To Ship"}</span>
              )}
            </Button>
            <Button
              type="button"
              sx={{ marginLeft: 2 }}
              color="tertiary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Close
            </Button>
          </div>
        </form>
      </Modal>
    );
  }
};

export default M2OUpdateModal;
