import { useEffect, useState } from "react";
import { allProperties } from "../categoryProperties";
import { categoryFields } from "../product-fields-modified";
import { getProductFieldDetails } from "../utils";
import RenderInput from "../../../../utils/RenderInput";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Checkbox,
  FormGroup,
} from "@mui/material";

const CategoryInfoForm = ({
  categoryForm,
  setAttributes,
  variants,
  setVariants,
  variationOn,
  setVariationOn,
  variantsCheckboxState,
  setVariantsCheckboxState,
}) => {
  const { formValues, setFormValues, errors } = categoryForm;

  useEffect(() => {
    let category = formValues["productCategory"];
    let sub_category = formValues["productSubcategory1"];
    if (category && category !== "F&B" && sub_category) {
      let category_data = allProperties[category];
      let properties = category_data?.hasOwnProperty(sub_category)
        ? category_data[sub_category]
        : category_data["default"] || [];
      let variants = properties?.filter((property) => property.required);
      let variants_checkbox_map = variants?.reduce((acc, variant) => {
        acc[variant.name] = false;
        return acc;
      }, {});
      setAttributes(properties);
      setVariants(variants);
      setVariantsCheckboxState(variants_checkbox_map);
    }
  }, [formValues.productSubcategory1]);

  const handleVariantCheckboxChange = (event) => {
    setVariantsCheckboxState({
      ...variantsCheckboxState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <form>
      {categoryFields.map((category_id) => {
        if (category_id === "productCategory") {
          return <></>;
        }
        let item = getProductFieldDetails(category_id);
        // console.log("Here is the item", item);
        return (
          item && (
            <RenderInput
              key={category_id}
              item={{
                ...item,
                error: errors?.[item?.id] ? true : false,
                helperText: errors?.[item.id] || "",
              }}
              state={formValues}
              stateHandler={setFormValues}
            />
          )
        );
      })}
      {/* TO-DO: Change this to Dropdown with appropriate field description such as "Does your product have variants on Size or Colour?" */}
      {/* <FormControl>
        <div className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block mt-2">
          Does your product have variants?
        </div>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={variationOn}
          onChange={(val) => setVariationOn(val.target.value)}
          sx={{ paddingLeft: "22px" }}
        >
          <FormControlLabel value="none" control={<Radio />} label="None" />
          <FormControlLabel
            value="attributes"
            control={<Radio />}
            label="Attribute"
          /> */}
          {/* uncomment below for uom_support */}
          {/* <FormControlLabel value="uom" control={<Radio />} label="UOM" /> */}
        {/* </RadioGroup>
      </FormControl> */}
      {variationOn === "attributes" ? (
        variants.length === 0 ? (
          <div>No attributes available for Variations!</div>
        ) : (
          <div>
            <label className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block mt-2">
              Select Variants
            </label>
            <Box sx={{ display: "flex" }}>
              <FormControl
                sx={{ ml: 3, display: "flex" }}
                component="fieldset"
                variant="standard"
              >
                <FormGroup sx={{ display: "flex" }}>
                  {variants?.map(({ name }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={variantsCheckboxState[name]}
                          onChange={handleVariantCheckboxChange}
                          name={name}
                        />
                      }
                      label={name}
                      key={name}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Box>
          </div>
        )
      ) : (
        <></>
      )}
    </form>
  );
};
export default CategoryInfoForm;