
export const getEnvVariableValue = (variable, defaultValue=null) => {
    const ENV = process.env.REACT_APP_ENV;
    const objString = process.env[variable] || "";
    if (!objString) {
        console.log(`Environment variable '${variable}' not found. Returning provided default: ${defaultValue}`);
        return defaultValue;
    }
    let obj;
    try {
        // Attempt to parse the environment variable as JSON
        obj = JSON.parse(objString);
        if (typeof obj !== 'object' || obj === null) {
            throw new Error("Parsed value is not a JSON object");
        }
        if (!obj.hasOwnProperty(ENV)) {
            throw new Error(`The key '${ENV}' is not found in the object`);
        }
        return obj[ENV];
    } catch (error) {
		console.log("The environment variable is not a JSON object or another error occurred:", error.message);
        return objString; // Return the string if it cannot be parsed as JSON
    }
};

export const constructS3Url = async(path) => {
    const region = process.env.REACT_APP_S3_REGION;
    const bucket = process.env.REACT_APP_S3_BUCKET;
    try {
        const regionString = '-' + region;
        const myBucket = bucket.replace('/public-assets','');
        let url = `https://${myBucket}.s3${regionString}.amazonaws.com/${path}`;
        return ({ url: url, path: path });
    } catch (err) {
        return err;
    }
};