import { useTheme } from "@mui/material/styles";

// 
import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import cogoToast from "cogo-toast";
import { useNavigate } from "react-router-dom";
import { getCall, postCall } from "../../../Api/axios";
import RenderInput from "../../../utils/RenderInput";
import useForm from "../../../hooks/useForm";
import { getFormErrors } from "../Product/utils";


const AddGoldSilverRates = () => {
	const theme = useTheme();
	return (
		<div className="container mx-auto my-8 px-2 md:px-8">
			<div className="mb-4 flex flex-row justify-between items-center">
				<label
					style={{ color: theme.palette.text.primary}}
					className="font-semibold text-2xl"
				>
					Add Gold & Silver Rates
				</label>
      </div>
      <div>
        <AddGoldRateFormBox/>
      </div>
		</div>
	);
};

export default AddGoldSilverRates;

const formFields = [
  {
    id: "cityName",
    title: "City Name",
    placeholder: "Enter City Name",
    type: "input",
    required: true,
  },
  {
    id: "goldPrice",
    title: "Gold Price (without GST)",
    placeholder: "Enter Gold Price for below Purity",
    type: "number",
    required: true,
  },
  {
    id: "goldPurity",
    title: "Gold Purity",
    placeholder: "Select Purity Here",
    type: "select",
    options: [
      { key: "24 KT", value: "24" },
      { key: "22 KT", value: "22" },  // default
      // { key: "18 KT", value: "18" },
    ],
    required: true,
    disableClearable: true,
  },
  {
    id: "silverPrice",
    title: "Silver Price (with GST)",
    placeholder: "Enter Silver Price for below Purity",
    type: "number",
    required: true,
  },
  {
    id: "silverPurity",
    title: "Silver Purity",
    placeholder: "Select Purity Here",
    type: "select",
    options: [
      { key: "100.0 (Pure Silver)", value: "100.0" },
      { key: "99.9 (Fine Silver)", value: "99.9" },
      { key: "92.5 (Sterling Silver)", value: "92.5" },  // default
    ],
    required: true,
    disableClearable: true,
  },
  {
    id: "entryDate",
    title: "Date of Entry",
    placeholder: "Enter Date",
    type: "date-picker",
    required: true,
    // isDisabled: true,
  },
];

const getTodaysDate = () => {
	const today = new Date();

	const day = String(today.getDate()).padStart(2, "0");
	const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
	const year = today.getFullYear();

	// Format the date as dd-mm-yyyy
	const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}



const AddGoldRateFormBox = () => {
  const navigate = useNavigate();
  const initialValues = {
    cityName: "",
    goldPrice: "",
    goldPurity: "22",
    silverPrice: "",
    silverPurity: "100.0",
    entryDate: getTodaysDate(),
  };
  const { formValues, setFormValues, errors, setErrors } = useForm({ ...initialValues })
  const [formSubmitted, setFormSubmited] = useState(false)


  const [User, setUser] = useState({});
  
  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id);
  }, []);
  
  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    console.log("x4 User : ", res);
    setUser(res[0]);
    return res[0];
  };


  const validate = () => {
    const formErrors = getFormErrors(formFields, formValues)
    setErrors({
      ...formErrors
    })
    return !Object.values(formErrors).some(val => val !== '')
  }

  const sendData = async () => {
    try {
      const url = `/api/v1/goldSilverRatesBySuperAdmins`;

      // formatting price data
      let goldPrice = parseFloat(formValues.goldPrice);
      let goldPurity =  parseInt(formValues.goldPurity);
      let silverPrice = parseFloat(formValues.silverPrice)/1.03; // 3% GST
      let silverPurity =  parseFloat(formValues.silverPurity);
      let priceData = { 
        goldRates: {
          "TWENTY_FOUR": 0,
          "TWENTY_TWO": 0
        },
        silverRates: {
          "ONE_HUNDRED_POINT_ZERO":0,
          "NINETY_NINE_POINT_NINE":0,
          "NINENTY_TWO_POINT_FIVE":0
        }
       };

      const getManipulatedPrice = (requiredPurity, metal) => {
        let price = 
          metal === "gold"
            ? parseFloat(((goldPrice / goldPurity) * requiredPurity).toFixed(2))
            : metal === "silver"
              ? parseFloat(((silverPrice / silverPurity) * requiredPurity).toFixed(2))
              : 0;
        return price;
      };

      switch (goldPurity) {
        case 24:
          priceData.goldRates.TWENTY_FOUR = parseFloat(goldPrice.toFixed(2));
          priceData.goldRates.TWENTY_TWO = getManipulatedPrice(22, "gold");
          break;
        case 22:
          priceData.goldRates.TWENTY_FOUR = getManipulatedPrice(24, "gold");
          priceData.goldRates.TWENTY_TWO = parseFloat(goldPrice.toFixed(2));
          break;
        // case 18:
        //   priceData.goldRate.TWENTY_FOUR = getManipulatedPrice(24, "gold");
        //   priceData.goldRate.TWENTY_TWO = getManipulatedPrice(22, "gold");
        //   break;
        default:
          console.error("Invalid purity");
          break;
      }
      switch (silverPurity) {
        case 100.0:
          priceData.silverRates.ONE_HUNDRED_POINT_ZERO = parseFloat(silverPrice.toFixed(2));
          priceData.silverRates.NINETY_NINE_POINT_NINE = getManipulatedPrice(99.9, "silver");
          priceData.silverRates.NINENTY_TWO_POINT_FIVE = getManipulatedPrice(92.5, "silver");
          break;
        case 99.9:
          priceData.silverRates.ONE_HUNDRED_POINT_ZERO = getManipulatedPrice(100.0, "silver");
          priceData.silverRates.NINETY_NINE_POINT_NINE = parseFloat(silverPrice.toFixed(2));
          priceData.silverRates.NINENTY_TWO_POINT_FIVE = getManipulatedPrice(92.5, "silver");
          break;
        case 92.5:
          priceData.silverRates.ONE_HUNDRED_POINT_ZERO = getManipulatedPrice(100.0, "silver");
          priceData.silverRates.NINETY_NINE_POINT_NINE = getManipulatedPrice(99.9, "silver");
          priceData.silverRates.NINENTY_TWO_POINT_FIVE = parseFloat(silverPrice.toFixed(2));
          break;
        default:
          console.error("Invalid purity");
          break;
      }
      const convertDateFormat = (dateString) => {
        const [day, month, year] = dateString.split("/");
        const formattedDate = `${month}/${day}/${year}`;
        return formattedDate;
      }

      console.log("x1 Sending data : ",2000, {"date" : convertDateFormat(formValues.entryDate), "priceLocation":formValues.cityName.toLowerCase(), "superAdminPrice": priceData});


      const res = await postCall(url, {"date" : convertDateFormat(formValues.entryDate), "priceLocation":formValues.cityName.toLowerCase(), "superAdminPrice": priceData});
      if (res) {
        //   navigate("/application/user-listings");
        cogoToast.success(`Gold & Silver Rates Added for ${formValues.cityName}.`);
        setFormValues(initialValues);
        setErrors({});
      }
      
    } catch (error) {
      console.log(error);
      cogoToast.error(error.response?.data?.error || error.message || "Error while adding gold/silver rates.");
    }
  };

  const checkDisabled = () => {
    return formValues.cityName === '' || formValues.goldPurity === '' || formValues.goldPrice === '' || formValues.silverPurity === '' || formValues.silverPrice === '' || formValues.entryDate === ''
  };

  const renderHeading = () => {
    return "Add Gold & Silver Rates";
  };

  const renderFormFields = () => {
    return formFields.map((item, key) => {
      return (
        <RenderInput
          key={key}
          item={{ ...item, error: errors?.[item.id] ? true : false, helperText: errors?.[item.id] || '' }}
          state={formValues}
          stateHandler={setFormValues}
        />
      )
    });
  };

  const handleBack = () => {
    navigate("/application/user-listings?view=admin");
  };

  const handleSubmit = () => {
    setFormSubmited(true)
    if (validate()) {
      console.log("x1 Submitting formvalues : ", formValues);
      sendData();
    }
  }

  // useEffect(() => {
  //   if (!formSubmitted) return
  //   validate()
  // }, [formValues])

  return (
    <div className="!p-5 overflow-auto bg-[#f0f0f0]">
      <div
        className="flex w-full mx-auto md:w-2/4 bg-white px-4 py-4 rounded-md shadow-xl h-max"
        style={{ minHeight: "75%" }}
      >
        <div className="m-auto w-10/12 md:w-3/4 h-max">
          <form>
            <p className="text-2xl font-semibold mb-4 text-center">
              {renderHeading()}
            </p>
            <div>{renderFormFields()}</div>
            <div className="flex mt-6 gap-4">
              <Button
                type="button"
                size="small"
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                type="button"
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={checkDisabled()}
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};