import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Tab,
  useTheme
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ArrowRight } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import RenderInput from "../../../utils/RenderInput";
import {
  areObjectsEqual,
  isEmailValid,
  isNumberOnly,
  isPhoneNoValid,
} from "../../../utils/validations";
import { useEffect } from "react";
import { getCall, postCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import moment from "moment";
import StoreTimingsRenderer from "./StoreTimingsRenderer";
import Fulfillments from "./Fulfillments";
import { PRODUCT_CATEGORY, DELIVERY_TYPE_LIST, PRODUCT_CATEGORY_2 } from "../../../utils/constants";

const fields = {
  provider: [
    {
      id: "name",
      title: "Name",
      placeholder: "Enter your Name",
      type: "input",
      required: true,
    },
    {
      id: "email",
      title: "Email",
      placeholder: "Enter your Email Address",
      type: "input",
      required: true,
    },
    {
      id: "mobile",
      title: "Mobile Number",
      placeholder: "Enter your Mobile Number",
      type: "input",
      required: true,
    },
  ],
  kyc: [
    {
      id: "gst_no",
      title: "GSTIN Certificate",
      placeholder: "GSTIN Certificate",
      type: "input",
      required: true,
    },
    {
      id: "gst_proof",
      title: "GSTIN Proof",
      type: "upload",
      file_type: "gst",
      required: true,
      fontColor: "#ffffff",
    },
    {
      id: "pan_no",
      title: "Aadhar Number",
      placeholder: "Enter your AADHAR",
      type: "input",
      required: true,
    },
    {
      id: "id_proof",
      title: "ID Proof",
      type: "upload",
      fontColor: "#ffffff",
      file_type: "id_proof",
      required: true,
    },
  ],
  bank: [
    {
      id: "bankName",
      title: "Bank Name",
      placeholder: "Enter Bank Name",
      type: "input",
      required: true,
    },
    {
      id: "branchName",
      title: "Branch Name",
      placeholder: "Enter Branch Name",
      type: "input",
      required: true,
    },
    {
      id: "IFSC",
      title: "IFSC Code",
      placeholder: "Enter IFSC Code",
      type: "input",
      required: true,
    },
    {
      id: "accHolderName",
      title: "Account Holder Name",
      placeholder: "Enter Account Holder Name",
      type: "input",
      required: true,
    },
    {
      id: "accNumber",
      title: "Account Number",
      placeholder: "Enter Account Number",
      type: "input",
      required: true,
    },
    {
      id: "cancelledCheque",
      title: "Upload Bank Passbook / Cancelled Cheque (Business Name / Trade Name should be clearly visible)",// "Bank Passbook/Cancelled Cheque",
      type: "upload",
      fontColor: "#ffffff",
      file_type: "cancelled_check",
      required: true,
    },
  ],
};

const categoriesList = Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
  return { key: value, value: key };
});

const productCategoriesList = Object.entries(PRODUCT_CATEGORY_2).map(([key, value]) => {
  return { key: value, value: key };
});

const deliveryTypeList = Object.entries(DELIVERY_TYPE_LIST).map(
  ([key, value]) => {
    return { key: value, value: key };
  }
);

let storeFields = [
  {
    id: "name",
    title: "Store Name",
    placeholder: "Enter Store Name",
    type: "input",
    email: true,
    required: true,
  },
  {
    id: "email",
    title: "Contact Email",
    placeholder: "Enter your Contact Email",
    type: "input",
    required: true,
  },
  {
    id: "manufacturer_name",
    title: "Manufacturer Name",
    placeholder: "Enter Manufacturer Name",
    type: "input",
    required: true,
  },
  {
    id: "manufacturer_address",
    title: "Manufacturer Address",
    placeholder: "Enter Manufacturer Address",
    type: "input",
    required: true,
  },
  {
    id: "origin",
    title: "Country of Origin",
    placeholder: "Enter Country of Origin",
    type: "input",
    required: true,
  },

  {
    id: "mobile",
    title: "Contact Mobile Number",
    placeholder: "Enter your Contact Mobile Number",
    type: "input",
    required: true,
    maxLength: 10,
    required: true,
  },
  {
    id: "category",
    title: "Select Your Category",
    placeholder: "Please Select Store Category",
    options: categoriesList,
    type: "select",
    required: true,
    previewOnly: true
  },
  {
    id: "productCategories",
    title: "Supported Product Categories",
    placeholder: "Please Select Supported Product Categories",
    options: productCategoriesList,
    type: "select-multiple",
    required: true,
  },
  {
    id: "location",
    title: "Store Location",
    placeholder: "Store Location",
    navigateTip: "Search for your store's location on the below map",
    type: "location-picker",
    required: true,
  },
  {
    id: "location_availability",
    title: "Location Availability",
    options: [
      { key: "PAN India", value: "pan_india" },
      { key: "City", value: "city" },
    ],
    type: "radio",
    required: true,
  },
  {
    id: "default_cancellable",
    title: "Default Cancellable Setting (Do you want to give customers the option to cancel after placing the order?)",
    options: [
      { key: "Cancellable (Yes)", value: "true" },
      { key: "Non-Cancellable (No)", value: "false" },
    ],
    type: "radio",
    required: true,
  },
  {
    id: "default_returnable",
    title: "Default Returnable Setting (Do you want to give customers the option to return the order?)",
    options: [
      { key: "Returnable (Yes)", value: "true" },
      { key: "Non-Returnable (No)", value: "false" },
    ],
    type: "radio",
    required: true,
  },
  {
    id: "defaultDeliveryOption",
    title: "Default Delivery Choice (Do you want to deliver the orders by yourself or let Aurika handle it?)",
    options: [
      { key: "Outsource Delivery (Let Aurika Tech fully manage the delivery.)", value: "platform_fulfilled" },
      { key: "Deliver Yourself (You will deliver the product to the buyer at the above address and update order status on the app.)", value: "seller_fulfilled" },
    ],
    type: "radio",
    required: true,
  },
  // TODO: Uncomment after COD is implemented
  // {
  //   id: "cod",
  //   title: "Available on COD?",
  //   options: [
  //     { key: "Yes (Both Prepaid & COD)", value: "true" },
  //     { key: "No (Only Prepaid)", value: "false" },
  //   ],
  //   type: "radio",
  //   required: true,
  // },

  {
    id: "country",
    title: "Country",
    placeholder: "Country",
    type: "input",
    required: true,
  },
  {
    id: "state",
    title: "State",
    placeholder: "State",
    type: "input",
    required: true,
  },
  {
    id: "city",
    title: "City",
    placeholder: "City",
    type: "input",
    required: true,
  },
  {
    id: "building",
    title: "Building",
    placeholder: "Building",
    type: "input",
    required: true,
  },
  {
    id: "area_code",
    title: "PIN Code",
    placeholder: "PIN code",
    type: "input",
    required: true,
  },
  {
    id: "locality",
    title: "Locality",
    placeholder: "Locality",
    placeholder: "Locality",
    type: "input",
    required: true,
  },
  {
    id: "logo",
    file_type: "logo",
    title: "Logo",
    type: "upload",
    required: true,
    fontColor: "#ffffff",
    excludedFiles: [".pdf"]
  },
];

const defaultStoreTimings = [
  {
    daysRange: { from: 1, to: 7 },
    timings: [{ from: "00:00", to: "23:59" }],
  },
];

const ProviderDetails = ({ isFromUserListing = false }) => {
  const navigate = useNavigate();
  const params = useParams();

  const theme = useTheme();

  const [tabValue, setTabValue] = useState('1');
  const [supportedFulfillments, setSupportedFulfillments] = useState({
    delivery: false,
    selfPickup: false,
    deliveryAndSelfPickup: false,
  });
  const [fulfillmentDetails, setFulfillmentDetails] = useState({
    deliveryDetails: {
      deliveryEmail: "",
      deliveryMobile: "",
    },
    selfPickupDetails: {
      selfPickupEmail: "",
      selfPickupMobile: "",
    },
    deliveryAndSelfPickupDetails: {
      deliveryEmail: "",
      deliveryMobile: "",
      selfPickupEmail: "",
      selfPickupMobile: "",
    },
  });

  const [storeDetailFields, setStoreDetailFields] = useState(storeFields);
  const [storeStatus, setStoreStatus] = useState("enabled");
  const [temporaryClosedTimings, setTemporaryClosedTimings] = useState({
    from: "00:00",
    to: "00:00",
  });
  const [temporaryClosedDays, setTemporaryClosedDays] = useState({
    from: 1,
    to: 7,
  });

  const [storeTimings, setStoreTimings] = useState([...defaultStoreTimings]);
  const [originalStoreTimings, setOriginalStoreTimings] = useState([
    ...defaultStoreTimings,
  ]);

  const [originalAddressDetails, setOriginalAddressDetails] = useState({});
  const [profileDetails, setProfileDetails] = useState({});
  const [storeDetails, setStoreDetails] = useState({
    location: {},
    category: "Fashion",
    productCategories: [productCategoriesList[0]],
    location_availability: "",
    name: "",
    email: "",
    mobile: "",
    manufacturer_address: "",
    manufacturer_name: "",
    // TODO: Uncomment this after implementing COD
    // cod: "",
    origin: "",
    default_cancellable: "",
    default_returnable: "",
    defaultDeliveryOption: "",
    cities: [
      { key: "Delhi", value: "delhi" },
      { key: "Pune", value: "pune" },
    ],
    country: "",
    state: "",
    city: "",
    address_city: "",
    building: "",
    area_code: "",
    locality: "",
    logo: "",
    logo_path: "",
    // holidays: [],
    radius: "100",
    // logisticsBppId: "",
    logisticsDeliveryType: DELIVERY_TYPE_LIST["Standard Delivery"],
  });

  const [errors, setErrors] = useState(null);
  const [logisticsAddressId, setLogisticsAddressId] = useState("");
  const [defaultStoreDetails, setDefaultStoreDetails] = useState({
    location: {},
    category: "Fashion",
    productCategories: [productCategoriesList[0]],
    name: "",
    email: "",
    mobile: "",
    manufacturer_address: "",
    manufacturer_name: "",
    // cod: "",
    origin: "",
    location_availability: "",
    default_cancellable: "",
    default_returnable: "",
    defaultDeliveryOption: "",
    cities: [
      { key: "Delhi", value: "delhi" },
      { key: "Pune", value: "pune" },
    ],
    country: "",
    state: "",
    city: "",
    address_city: "",
    building: "",
    area_code: "",
    locality: "",
    logo: "",
    logo_path: "",
    // holidays: [],
    radius: "100",
    logisticsBppId: "",
    logisticsDeliveryType: "",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getAvailableFulfillments = (fulfillments) => {
    let hasF1 = false;
    let hasF2 = false;
    let hasF3 = false;
    let deliveryEmail = "";
    let deliveryMobile = "";
    let selfPickupEmail = "";
    let selfPickupMobile = "";
    let email_delivery = "";
    let mobile_delivery = "";
    let email_self = "";
    let mobile_self = "";

    fulfillments?.forEach((fulfillment) => {
      if (fulfillment.id === "f1") {
        hasF1 = true;
        deliveryEmail = fulfillment.contact.email;
        deliveryMobile = fulfillment.contact.phone;
      }

      if (fulfillment.id === "f2") {
        hasF2 = true;
        selfPickupEmail = fulfillment.contact.email;
        selfPickupMobile = fulfillment.contact.phone;
      }

      if (fulfillment.id === "f3") {
        hasF3 = true;
        email_delivery = fulfillment.contact.delivery.email;
        mobile_delivery = fulfillment.contact.delivery.phone;
        email_self = fulfillment.contact.pickup.email;
        mobile_self = fulfillment.contact.pickup.phone;
      }
    });

    return {
      supportedFulfillments: {
        delivery: hasF1,
        selfPickup: hasF2,
        deliveryAndSelfPickup: hasF3,
      },
      fulfillmentDetails: {
        deliveryDetails: {
          deliveryEmail,
          deliveryMobile,
        },
        selfPickupDetails: {
          selfPickupEmail,
          selfPickupMobile,
        },
        deliveryAndSelfPickupDetails: {
          deliveryEmail: email_delivery,
          deliveryMobile: mobile_delivery,
          selfPickupEmail: email_self,
          selfPickupMobile: mobile_self,
        },
      },
    };
  };

  const getOrgDetails = async (id) => {
    try {
      const url = `/api/v1/organizations/${id}`;
      const res = await getCall(url);
      setLogisticsAddressId(res?.providerDetail?.storeDetails?.logisticsAddressId || "");
      setProfileDetails({
        provider: {
          email: res.user.email,
          mobile: res.user.mobile,
          name: res.user.name,
        },
        kyc: {
          gst_no: res?.providerDetail?.GSTN?.GSTN,
          gst_proof: constructProofUrl(res?.providerDetail?.GSTN?.proof?.path),
          pan_no: res?.providerDetail?.PAN?.PAN,
          id_proof: constructProofUrl(res?.providerDetail?.idProof?.path),
        },
        bank: {
          bankName: res?.providerDetail?.bankDetails?.bankName,
          branchName: res?.providerDetail?.bankDetails?.branchName,
          IFSC: res?.providerDetail?.bankDetails?.IFSC,
          accHolderName: res?.providerDetail?.bankDetails?.accHolderName,
          accNumber: res?.providerDetail?.bankDetails?.accNumber,
          cancelledCheque: constructProofUrl(
            res?.providerDetail?.bankDetails?.cancelledCheque?.path
          ),
        },
      });
      function constructProofUrl(originalUrl) {
        if (!originalUrl) {
          return undefined;
        }
        console.log(originalUrl);
        let myBucket = process.env.REACT_APP_S3_BUCKET;
        let regionString = '-' + process.env.REACT_APP_S3_REGION;
        let url = `https://${myBucket}.s3${regionString}.amazonaws.com`;
        const baseUrl = url;
        const replacementString = "/public-assets/";

        let modifiedImagePath = originalUrl.replace(
          new RegExp(`^${baseUrl}/`),
          ""
        );
        console.log("Modified Image Path", modifiedImagePath);

        let newlymodifiedImagePath = modifiedImagePath.replace(
          new RegExp(replacementString),
          "/"
        );
        console.log("WITHOUT PUBLIC ASSETS", newlymodifiedImagePath);

        const modifiedUrl = newlymodifiedImagePath
          .replace(/\+/g, "%2B")
          .replace(/ /g, "+");

        const newUrl = `${baseUrl}/${modifiedUrl}`;
        console.log("CONSTRUCT FUNCTION DONE WORKING");
        console.log(newUrl);
        return newUrl;
      }

      console.log(
        "res?.providerDetail?.storeDetails?.logisticsDeliveryType=====>",
        res?.providerDetail?.storeDetails?.logisticsDeliveryType
      );
      console.log("Logo URL:", res?.providerDetail?.storeDetails?.logo?.url);
      console.log("New URL ", constructProofUrl(res?.providerDetail?.storeDetails?.logo?.url));
      console.log("Path : ", res?.providerDetail?.storeDetails?.logo?.path);
      console.log("New Path : ", res?.providerDetail?.storeDetails?.logo?.path);
      const productCategories = res?.providerDetail?.storeDetails?.productCategories;
      let storeData = {
        name: res.providerDetail.name,
        manufacturer_name: res.providerDetail.storeDetails.manufacturer_name || "",
        manufacturer_address: res.providerDetail.storeDetails.manufacturer_address || "",
        origin: res.providerDetail.storeDetails.origin || "",
        // cod: res.providerDetail.storeDetails.cod,
        email: res.providerDetail.storeDetails?.supportDetails.email || "",
        mobile: res.providerDetail.storeDetails?.supportDetails.mobile || "",
        category: res?.providerDetail?.storeDetails?.category || "Fashion",
        productCategories: productCategories ? productCategories.map(c=>productCategoriesList.find(i=>i.value===c)) : storeDetails.productCategories, 
        location: res?.providerDetail?.storeDetails?.location || "",
        location_availability: res.providerDetail.storeDetails
          ? res.providerDetail.storeDetails.locationAvailabilityPANIndia == true
            ? "pan_india"
            : "city"
          : "",
        cities: res?.providerDetail?.storeDetails?.city || [],
        default_cancellable: res?.providerDetail?.storeDetails?.defaultCancellable ? "true":"false",
        default_returnable: res?.providerDetail?.storeDetails?.defaultReturnable ? "true":"false",
        defaultDeliveryOption: res?.providerDetail?.storeDetails?.defaultDeliveryOption || "",
        country: res.providerDetail?.storeDetails?.address?.country || "",
        state: res.providerDetail?.storeDetails?.address?.state || "",
        city: res.providerDetail?.storeDetails?.address.city || "",
        address_city: res.providerDetail?.storeDetails?.address.city || "",
        building: res.providerDetail?.storeDetails?.address?.building || "",
        area_code: res.providerDetail?.storeDetails?.address?.area_code || "",
        locality: res.providerDetail?.storeDetails?.address?.locality || "",
        logo: res?.providerDetail?.storeDetails?.logo?.url || "",
        logo_path: res?.providerDetail?.storeDetails?.logo?.path || "",

        // holidays:
        //   res?.providerDetail?.storeDetails?.storeTiming?.holidays || [],
        radius: res?.providerDetail?.storeDetails?.radius?.value || "100",
        logisticsBppId: res?.providerDetail?.storeDetails?.logisticsBppId || "",
        logisticsDeliveryType:
          res?.providerDetail?.storeDetails?.logisticsDeliveryType || "Standard Delivery",
      };
      let storeDataDeepCopy = Object.assign({}, JSON.parse(JSON.stringify(storeData)));
      // Restore product categories from the original data reference because
      // MUI Autocomplete component compares provided value with options directly
      // using value == option, which returns false for objects/arrays pointing to different locations in memory.
      // This is useful when providing initial value for a component and that value is either an object or an array.
      storeDataDeepCopy.productCategories = storeData.productCategories;
      setOriginalAddressDetails(res.providerDetail.storeDetails.address);
      const fulfillments = res.providerDetail.storeDetails.fulfillments;
      const { supportedFulfillments, fulfillmentDetails } =
        getAvailableFulfillments(fulfillments);

      setSupportedFulfillments(supportedFulfillments);
      setFulfillmentDetails((prevDetails) => ({
        ...prevDetails,
        ...fulfillmentDetails,
      }));

      const storeTimingDetails = res?.providerDetail?.storeDetails?.storeTiming;

      setStoreStatus(storeTimingDetails.status);
      setTemporaryClosedTimings(storeTimingDetails?.closed);
      setTemporaryClosedDays(storeTimingDetails.closedDays);
      setLogisticsAddressId(
        res.providerDetail?.storeDetails?.logisticsAddressId || ""
      );
      setStoreDetails(storeDataDeepCopy);
      setDefaultStoreDetails(storeDataDeepCopy);
      setStoreTimings(
        res?.providerDetail?.storeDetails?.storeTiming?.enabled ||
          defaultStoreTimings
      );
      setOriginalStoreTimings(
        res?.providerDetail?.storeDetails?.storeTiming?.enabled ||
          defaultStoreTimings
      );
      console.log("storeData=====>", storeData);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("storeDetails=====>", storeDetails);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let provider_id = params?.id;
    getOrgDetails(provider_id);
  }, [params.id]);

  function addAfter(array, index, newItem) {
    return [...array.slice(0, index), newItem, ...array.slice(index)];
  }

  const getStoreTimesErrors = () => {
    let values = storeTimings.reduce((acc, storeTiming) => {
      acc.push(storeTiming.daysRange.from);
      acc.push(storeTiming.daysRange.to);
      storeTiming.timings.forEach((element) => {
        acc.push(element.from);
        acc.push(element.to);
      });
      return acc;
    }, []);
    return values.some((value) => value === "")
      ? "Please fix all details of timings!"
      : "";
  };

  const validate = () => {
    const formErrors = {};
    formErrors.email =
      storeDetails.email?.trim() === ""
        ? "Support Email is required"
        : !isEmailValid(storeDetails.email)
        ? "Please enter a valid email address"
        : "";
    formErrors.mobile =
      storeDetails.mobile?.trim() === ""
        ? "Support Mobile Number is required"
        : !isPhoneNoValid(storeDetails.mobile)
        ? "Please enter a valid mobile number"
        : "";

    formErrors.category =
      storeDetails.category?.trim() === ""
        ? "Supported Product Category is required"
        : "";
    // formErrors.location = storeDetails.location.trim() === '' ? 'Location is required' : ''
    if (storeDetails.location_availability === "city") {
      formErrors.cities =
        storeDetails.cities.length === 0 ? "City is required" : "";
    } else {
    }
    if(storeDetails.default_cancellable === ""){
      formErrors.default_cancellable = "Please select whether you support cancelling orders or not"
    }
    if(storeDetails.default_returnable === ""){
      formErrors.default_returnable = "Please select whether you support returning orders or not"
    }
    if(storeDetails.defaultDeliveryOption === ""){
      formErrors.defaultDeliveryOption = "Please choose whether you would like to handle delivery yourself or not"
    }
    // TODO: Uncomment this when COD is ready
    // if(storeDetails.cod === ""){
    //   formErrors.cod = "Please select whether you support Cash on Delivery or not"
    // }
    formErrors.name =
      storeDetails.name?.trim() === "" ? "Store Name is required" : "";
    formErrors.country =
      storeDetails.country?.trim() === "" ? "Country is required" : "";
    formErrors.state =
      storeDetails.state?.trim() === "" ? "State is required" : "";
    formErrors.address_city =
      storeDetails.address_city?.trim() === "" ? "City is required" : "";
    formErrors.building =
      storeDetails.building?.trim() === "" ? "Building is required" : "";
    formErrors.area_code =
      storeDetails.area_code?.trim() === "" ? "PIN Code is required" : "";
    formErrors.logo =
      storeDetails.logo?.trim() === "" ? "Logo is required" : "";

    if (!isFromUserListing) {
      if (storeStatus === "enabled") {
        // const length = storeDetails.holidays?.length;
        // formErrors.holidays =
        //   length === 0 || !length ? "Holidays are required" : "";
        formErrors.storeTimes = getStoreTimesErrors();
      } else {
        // formErrors.holidays = "";
        formErrors.storeTimes = "";
      }
    } else {
    }

    formErrors.radius =
      storeDetails.radius?.trim() === ""
        ? "Serviceable Radius/Circle is required"
        : !isNumberOnly(storeDetails?.radius)
        ? "Please enter only digit"
        : "";
    //  formErrors.logisticsBppId = storeDetails.logisticsBppId.trim() === "" ? "Logistics Bpp Id is required" : "";
    //  formErrors.logisticsDeliveryType = storeDetails.logisticsDeliveryType.trim() === "" ? "Logistics Bpp Id is required" : "";
    formErrors.logisticsBppId = "";
    formErrors.logisticsDeliveryType = "";

    formErrors.deliveryEmail =
      supportedFulfillments.delivery !== false
        ? fulfillmentDetails.deliveryDetails?.deliveryEmail?.trim() === ""
          ? "Delivery Email is required"
          : !isEmailValid(fulfillmentDetails.deliveryDetails?.deliveryEmail)
          ? "Please enter a valid email address"
          : ""
        : "";

    formErrors.deliveryMobile =
      supportedFulfillments.delivery !== false
        ? fulfillmentDetails.deliveryDetails?.deliveryMobile?.trim() === ""
          ? "Mobile Number is required"
          : !isPhoneNoValid(fulfillmentDetails.deliveryDetails?.deliveryMobile)
          ? "Please enter a valid mobile number"
          : ""
        : "";

    formErrors.selfPickupEmail =
      supportedFulfillments.selfPickup !== false
        ? fulfillmentDetails.selfPickupDetails?.selfPickupEmail?.trim() === ""
          ? "Delivery Email is required"
          : !isEmailValid(fulfillmentDetails.selfPickupDetails?.selfPickupEmail)
          ? "Please enter a valid email address"
          : ""
        : "";

    formErrors.selfPickupMobile =
      supportedFulfillments.selfPickup !== false
        ? fulfillmentDetails.selfPickupDetails?.selfPickupMobile?.trim() === ""
          ? "Mobile Number is required"
          : !isPhoneNoValid(
              fulfillmentDetails.selfPickupDetails?.selfPickupMobile
            )
          ? "Please enter a valid mobile number"
          : ""
        : "";

    if (supportedFulfillments.deliveryAndSelfPickup) {
      formErrors.deliveryAndSelfPickupDetails = {};

      const deliveryAndSelfPickupDetails =
        fulfillmentDetails?.deliveryAndSelfPickupDetails || {};
      const deliveryEmail = deliveryAndSelfPickupDetails.deliveryEmail?.trim();
      const deliveryMobile =
        deliveryAndSelfPickupDetails.deliveryMobile?.trim();
      const selfPickupEmail =
        deliveryAndSelfPickupDetails.selfPickupEmail?.trim();
      const selfPickupMobile =
        deliveryAndSelfPickupDetails.selfPickupMobile?.trim();

      formErrors.deliveryAndSelfPickupDetails.deliveryEmail = !deliveryEmail
        ? "Delivery Email is required"
        : !isEmailValid(deliveryEmail)
        ? "Please enter a valid email address"
        : "";

      formErrors.deliveryAndSelfPickupDetails.deliveryMobile = !deliveryMobile
        ? "Mobile Number is required"
        : !isPhoneNoValid(deliveryMobile)
        ? "Please enter a valid mobile number"
        : "";

      formErrors.deliveryAndSelfPickupDetails.selfPickupEmail = !selfPickupEmail
        ? "Delivery Email is required"
        : !isEmailValid(selfPickupEmail)
        ? "Please enter a valid email address"
        : "";

      formErrors.deliveryAndSelfPickupDetails.selfPickupMobile =
        !selfPickupMobile
          ? "Mobile Number is required"
          : !isPhoneNoValid(selfPickupMobile)
          ? "Please enter a valid mobile number"
          : "";

      // Check if all nested properties are empty, then delete the entire object from formErrors
      if (
        Object.keys(formErrors.deliveryAndSelfPickupDetails).every(
          (key) => formErrors.deliveryAndSelfPickupDetails[key] === ""
        )
      ) {
        delete formErrors.deliveryAndSelfPickupDetails;
      }
    } else {
      delete formErrors.deliveryAndSelfPickupDetails;
    }

    if (storeStatus === "closed") {
      if (
        temporaryClosedTimings.from === temporaryClosedTimings.to &&
        temporaryClosedTimings.from !== "Invalid Date"
      ) {
        formErrors.temporaryClosedTimings =
          "Opening and closing times cannot be the same";
      } else if (temporaryClosedTimings.from === "Invalid date") {
        formErrors.temporaryClosedTimings =
          "Please provide a valid opening time";
      } else if (temporaryClosedTimings.to === "Invalid date") {
        formErrors.temporaryClosedTimings =
          "Please provide a valid closing time";
      } else {
        formErrors.temporaryClosedTimings = "";
      }
    }

    setErrors(formErrors);
    if (Object.values(formErrors).some((val) => val !== "")) {
      console.log(formErrors);
      cogoToast.error("Please fill in all required fields appropriately!");
    }
    return !Object.values(formErrors).some((val) => val !== "");
  };

  const anyChangeInData = () => {
    // TODO: debug following
    let is_form_updated = !areObjectsEqual(storeDetails, defaultStoreDetails);
    let is_store_time_updated = !areObjectsEqual(
      storeTimings,
      originalStoreTimings
    );
    //return is_form_updated || is_store_time_updated;
    return true;
  };

  const getFulfillmentsPayloadFormat = () => {
    let fulfillments = [];
    if (supportedFulfillments.delivery) {
      let deliveryDetails = {
        id: "f1",
        type: "delivery",
        contact: {
          email: fulfillmentDetails.deliveryDetails.deliveryEmail,
          phone: fulfillmentDetails.deliveryDetails.deliveryMobile,
        },
      };
      fulfillments.push(deliveryDetails);
    }

    if (supportedFulfillments.selfPickup) {
      let selfPickupDetails = {
        id: "f2",
        type: "pickup",
        contact: {
          email: fulfillmentDetails.selfPickupDetails.selfPickupEmail,
          phone: fulfillmentDetails.selfPickupDetails.selfPickupMobile,
        },
      };
      fulfillments.push(selfPickupDetails);
    }

    if (supportedFulfillments.deliveryAndSelfPickup) {
      let deliveryAndSelfPickupDetails = {
        id: "f3",
        type: "delivery&pickup",
        contact: {
          delivery: {
            email:
              fulfillmentDetails.deliveryAndSelfPickupDetails.deliveryEmail,
            phone:
              fulfillmentDetails.deliveryAndSelfPickupDetails.deliveryMobile,
          },
          pickup: {
            email:
              fulfillmentDetails.deliveryAndSelfPickupDetails.selfPickupEmail,
            phone:
              fulfillmentDetails.deliveryAndSelfPickupDetails.selfPickupMobile,
          },
        },
      };
      fulfillments.push(deliveryAndSelfPickupDetails);
    }

    return fulfillments;
  };

  const getStoreTimingsPayloadFormat = () => {
    let storeTiming = {};
    storeTiming.status = storeStatus;
    // storeTiming.holidays = storeDetails.holidays;
    storeTiming.enabled = storeTimings;
    storeTiming.closed = temporaryClosedTimings;
    storeTiming.closedDays = temporaryClosedDays;
    return storeTiming;
  };

  const startWithHttpRegex = new RegExp("^http");
  //TODO : IMPLEMENT A ROOLBACK FUNCTIONALITY IN THE CASE FIRST API CALL IS SUCCESSFULL AND SECOND FAILS
  const onUpdate = async () => {
    if (anyChangeInData() && validate()) {
      const provider_id = params?.id;
      const url = `/api/v1/organizations/${provider_id}/storeDetails`;
      const url2 = `/api/v1/organizations/${provider_id}/updateStoreName`;
      const {
        category,
        logo,
        logo_path,
        location_availability,
        default_cancellable,
        default_returnable,
        defaultDeliveryOption,
        mobile,
        email,
        cities,
        manufacturer_address,
        manufacturer_name,
        origin,
        cod,
        building,
        state,
        address_city,
        country,
        area_code,
        location,
        locality = "",
      } = storeDetails;
      // const manufacturer_details ={
      //   manufacturer_address:manufacturer_address,
      //   manufacturer_name:manufacturer_name,
      //   origin:origin,
      //   cod:cod,
      // }
      const locationAvailability =
        location_availability === "pan_india" ? true : false;
      const addressDetails = {
        building: building,
        city: address_city,
        state: state,
        country: country,
        area_code: area_code,
        locality: locality,
      };
      console.log("Address Details: ", addressDetails);
      let iso8601 = "";
      if (
        storeDetails.frequency &&
        storeDetails.StoreTimeType === "frequency"
      ) {
        // Create a duration object with the hours you want to convert
        const duration = moment.duration(
          parseInt(storeDetails.frequency),
          "hours"
        );

        // Format the duration in ISO 8601 format
        iso8601 = duration.toISOString();
      } else {
      }
      let payload = {};
      let fulfillments = getFulfillmentsPayloadFormat();
      let storeTiming = getStoreTimingsPayloadFormat();
      const isAddressChanged = !areObjectsEqual(addressDetails, originalAddressDetails) ? 1 : 0;

      const generateNewLogisticsAddressId = async () => {
        const now = new Date();

        const formattedTime = now.toISOString().substring(11, 19).replace(/:/g, "");

        const randomString = Math.random().toString(36).substring(2, 8);

        const logisticAddressId = formattedTime + randomString;
        return logisticAddressId;
      };
      if (!areObjectsEqual(addressDetails, originalAddressDetails)) {
        console.log("Inside the generate Logistic Address Id");

        const id = await generateNewLogisticsAddressId();
        payload = {
          category: category,
          // productCategories : storeDetails.productCategories.map(c=>c.value),
          isAddressChanged: isAddressChanged,
          locationAvailabilityPANIndia: locationAvailability,
          defaultCancellable: eval(default_cancellable),
          defaultReturnable: eval(default_returnable),
          defaultDeliveryOption: defaultDeliveryOption,
          address: addressDetails,
          manufacturer_address: manufacturer_address,
          manufacturer_name: manufacturer_name,
          cod: cod,
          origin: origin,
          supportDetails: {
            email,
            mobile,
          },
          fulfillments,
          storeTiming,
          radius: {
            unit: "km",
            value: storeDetails.radius || "",
          },
          logisticsBppId: storeDetails.logisticsBppId,
          logisticsDeliveryType: storeDetails.logisticsDeliveryType,
          logisticsAddressId: id,
        };
        console.log("Here is the logisticAddressId main", id);
      }
      else {
        console.log("Here is the logisticAddressId main", logisticsAddressId);
        payload = {
          category: category,
          // productCategories : storeDetails.productCategories.map(c=>c.value),
          isAddressChanged: isAddressChanged,
          locationAvailabilityPANIndia: locationAvailability,
          defaultCancellable: eval(default_cancellable),
          defaultReturnable: eval(default_returnable),
          defaultDeliveryOption: defaultDeliveryOption,
          address: addressDetails,
          manufacturer_address: manufacturer_address,
          manufacturer_name: manufacturer_name,
          cod: cod,
          origin: origin,
          supportDetails: {
            email,
            mobile,
          },
          fulfillments,
          storeTiming,
          radius: {
            unit: "km",
            value: storeDetails.radius || "",
          },
          logisticsBppId: storeDetails.logisticsBppId,
          logisticsDeliveryType: storeDetails.logisticsDeliveryType,
        };
      }

      let payload2 = { name: storeDetails.name, };
      console.log("Here is the payload I am sending ");
      console.dir(payload, { depth: null });
      postCall(url2, payload2)
        .then((resp) => {
          console.log("Provider Store Name Updates . Dev I am working Fine");
        })
        .catch((error) => {
          console.log("Dev I am Unwell");
          console.log(error);
        });
      if (location) {
        payload.location = location;
        // delete payload.location._id;
      } else {
      }
      if (locationAvailability == false) {
        payload["city"] = cities;
      } else {
      }

      if (!startWithHttpRegex.test(storeDetails.logo)) {
        payload.logo = logo;
      } else {
        const cleanS3Url = (url) => new URL(url).pathname.substring(1);
        payload.logo = decodeURIComponent(cleanS3Url(logo));
      }

      console.log("Here is the payload");
      console.dir(payload, { depth: null });
      postCall(url, payload)
        .then((resp) => {
          cogoToast.success("Store details updated successfully");
          // We don't need to fetch organization details again as we are navigating to a different page
          // getOrgDetails(provider_id);
          navigate("/application/inventory");
        })
        .catch((error) => {
          console.log(error);
          cogoToast.error(error.response.data.error);
        });
    }
  };

  useEffect(() => {
    if (storeDetails.location_availability == "city") {
      let fieldsWithCityInput = addAfter(storeDetailFields, 5, {
        id: "cities",
        title: "Select Cities",
        placeholder: "Please Select Cities",
        options: [
          { key: "Delhi", value: "delhi" },
          { key: "Pune", value: "pune" },
          { key: "Bengaluru", value: "bengaluru" },
          { key: "Kolkata", value: "kolkata" },
          { key: "Noida", value: "noida" },
        ],
        type: "multi-select",
        required: true,
      });
      setStoreDetailFields(fieldsWithCityInput);
    } else {
      setStoreDetailFields(storeFields);
    }
  }, [storeDetails.location_availability]);

  let userRole = JSON.parse(localStorage.getItem("user"))?.role?.name;

  const renderStoreDetails = () => {
    return <>
      <p className="text-2xl font-semibold mb-4 mt-14">Store Details</p>
              {[
                "name",
                "logo",
                // "category",
                // "productCategories",
                "location",
                "country",
                "state",
                "city",
                "building",
                "area_code",
                "locality",
              ].map((fieldId) => {
                const item = storeFields.find((item) => item.id === fieldId);

                return (
                  <RenderInput
                  key={fieldId}
                    item={{
                      ...item,
                      error: !!errors?.[item.id],
                      helperText: errors?.[item.id] || "",
                    }}
                    state={storeDetails}
                    stateHandler={setStoreDetails}
                    previewOnly={item.previewOnly || false}
                  />
                );
              })}
              <p className="text-2xl font-semibold mb-4 mt-14">Contact Details</p>
              <i className="text-lg m-2 ">Enter your contact details for customers to reach out for direct queries</i>
              {[
                "email",
                "mobile"
              ].map((fieldId) => {
                const item = storeFields.find((item) => item.id === fieldId);

                return (
                  <RenderInput
                    item={{
                      ...item,
                      error: !!errors?.[item.id],
                      helperText: errors?.[item.id] || "",
                    }}
                    state={storeDetails}
                    stateHandler={setStoreDetails}
                  />
                );
              })}
              <p className="text-2xl font-semibold mb-4 mt-14">Default Product Details</p>
              <i className="text-lg m-2 ">These are mandatory e-commerce fields. You can enter your business name and address here</i>
              {[
                  "manufacturer_name",
                  "manufacturer_address",
                  "origin"
                ].map(
                (fieldId) => {
                  const item = storeFields.find((item) => item.id === fieldId);

                  return (
                    <RenderInput
                      item={{
                        ...item,
                        error: !!errors?.[item.id],
                        helperText: errors?.[item.id] || "",
                      }}
                      state={storeDetails}
                      stateHandler={setStoreDetails}
                    />
                  );
                })}
              <p className="text-2xl font-semibold mb-4 mt-14">Default Delivery Details</p>
              {/* TODO: Add "cod" at the end of this Array when ready for COD */}
              {[
                "location_availability",
                "default_cancellable",
                "default_returnable",
                "defaultDeliveryOption"
              ].map((fieldId) => {
                const item = storeFields.find((item) => item.id === fieldId);

                  return (
                    <RenderInput
                      item={{
                        ...item,
                        error: !!errors?.[item.id],
                        helperText: errors?.[item.id] || "",
                      }}
                      state={storeDetails}
                      stateHandler={setStoreDetails}
                    />
                  );
                }
              )}
              <Fulfillments
                errors={errors}
                supportedFulfillments={supportedFulfillments}
                setSupportedFulfillments={setSupportedFulfillments}
                fulfillmentDetails={fulfillmentDetails}
                setFulfillmentDetails={setFulfillmentDetails}
              />
              <RenderInput
                item={{
                  id: "logisticsDeliveryType",
                  title: "Logistics Delivery Type",
                  placeholder: "Logistics Delivery Type",
                  error: errors?.["logisticsDeliveryType"] ? true : false,
                  helperText: errors?.["logisticsDeliveryType"] || "",
                  options: deliveryTypeList,
                  type: "select",
                }}
                state={storeDetails}
                stateHandler={setStoreDetails}
              />
    </>
  }
  const renderStoreTimings = () => {
    return <>
      {!isFromUserListing && (
                <>
                  {/* <RenderInput
                    item={{
                      id: "logisticsBppId",
                      title: "Logistics Bpp Id",
                      placeholder: "Logistics Bpp Id",
                      type: "input",
                      error: errors?.["logisticsBppId"] ? true : false,
                      helperText: errors?.["logisticsBppId"] || "",
                    }}
                    state={storeDetails}
                    stateHandler={setStoreDetails}
                  /> */}

                  <p className="text-2xl font-semibold mb-2 mt-14">
                    Store Timings
                  </p>
                  <i className="text-lg m-2 "><span className="font-bold not-italic">DON'T MODIFY. </span>These timings indicate when customers can view and order your products.</i>
                  <RenderInput
                    item={{
                      id: "radius",
                      title: "Serviceable Radius/Circle (in Kilometer)",
                      placeholder: "Serviceable Radius/Circle (in Kilometer)",
                      type: "input",
                      error: errors?.["radius"] ? true : false,
                      helperText: errors?.["radius"] || "",
                      required: true,
                      
                    }}
                    state={storeDetails}
                    stateHandler={setStoreDetails}
                  />
                  <div className="py-1 flex flex-col">
                    <FormControl component="fieldset">
                      <label className="text-sm py-2 ml-1 font-medium text-left text-[#606161] inline-block">
                        Store Status
                        <span className="text-[#FF0000]"> *</span>
                      </label>
                      <RadioGroup
                        value={storeStatus}
                        onChange={(e) => {
                          setStoreStatus(e.target.value);
                        }}
                      >
                        <div className="flex flex-row">
                          {[
                            { key: "Enabled", value: "enabled" },
                            { key: "Temporarily Closed", value: "closed" },
                            { key: "Disabled", value: "disabled" },
                          ].map((radioItem, i) => (
                            <FormControlLabel
                              key={i}
                              value={radioItem.value}
                              control={
                                <Radio
                                  size="small"
                                  checked={radioItem.value === storeStatus}
                                />
                              }
                              label={
                                <div className="text-sm font-medium text-[#606161]">
                                  {radioItem.key}
                                </div>
                              }
                            />
                          ))}
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <StoreTimingsRenderer
                    errors={errors}
                    storeStatus={storeStatus}
                    storeTimings={storeTimings}
                    setStoreTimings={setStoreTimings}
                    temporaryClosedTimings={temporaryClosedTimings}
                    setTemporaryClosedTimings={setTemporaryClosedTimings}
                    temporaryClosedDays={temporaryClosedDays}
                    setTemporaryClosedDays={setTemporaryClosedDays}
                  />
                </>
              )}
    </>
  }
  const renderProfileDetails = () => {
    const renderFields = (fieldGroup) => (
      <div>
        {fields[fieldGroup].map((item) => (
          <RenderInput
            previewOnly={true}
            item={item}
            state={profileDetails[fieldGroup]}
            statehandler={(updatedState) =>
              setProfileDetails((prevDetails) => ({
                ...prevDetails,
                [fieldGroup]: updatedState,
              }))
            }
            key={item.id}
          />
        ))}
      </div>
    );
    return (
      <>
        <p className="text-2xl font-semibold mb-4 mt-14">Provider Details</p>
        {renderFields("provider")}
        <p className="text-2xl font-semibold mb-4 mt-14">KYC Details</p>
        {renderFields("kyc")}
        <p className="text-2xl font-semibold mb-4 mt-14">Bank Details</p>
        {renderFields("bank")}
      </>
    )
  }

  return (
    <div>
      <div className="container mx-auto my-8 px-2 md:px-8">
        <div>
          <div
            className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
            style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
          >
            <div className="m-auto w-10/12 md:w-3/4 h-max">
              <BackNavigationButton
                onClick={() => {
                  userRole == "Super Admin"
                    ? navigate("/product-listings")
                    : navigate("/application/inventory");
                }}
              />
              {/* <p className="text-2xl font-semibold mb-4">Provider Details</p>
              {providerFields.map((item) => (
                <RenderInput previewOnly={true} item={item} state={providerDetails} statehandler={setProviderDetails} />
              ))}
              <p className="text-2xl font-semibold mb-4 mt-14">KYC Details</p>
              {kycFields.map((item) => (
                <RenderInput previewOnly={true} item={item} state={kycDetails} statehandler={setKycDetails} />
              ))}
              <p className="text-2xl font-semibold mb-4 mt-14">Bank Details</p>
              {bankFields.map((item) => (
                <RenderInput previewOnly={true} item={item} state={bankDetails} statehandler={setBankDetails} />
              ))} */}
              {/* {renderStoreDetails()}
              {renderStoreTimings()} */}
              
              <Box sx={{ width: "100%", typography: "body1" }}>
                  <TabContext value={tabValue}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <TabList
                        onChange={handleTabChange}
                        sx={{ '& .MuiTabs-indicator': { backgroundColor: theme.palette.tertiary.main },
                              '& .MuiTab-root.Mui-selected': { color: theme.palette.tertiary.main,
                                                               fontWeight: 700 } }}
                        variant="scrollable"
                      >
                        <Tab sx={{ minWidth: "150px" }}
                        label="Store Details"
                        value="1"
                        />
                        <Tab sx={{ minWidth: "150px" }}
                        label="Store Timings"
                        value="2"
                        />
                        <Tab sx={{ minWidth: "150px" }}
                        label="Seller Profile"
                        value="3"
                        />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div className="mt-2">{renderStoreDetails()}</div>
                    </TabPanel>
                    <TabPanel value="2">
                      <div className="mt-2">{renderStoreTimings()}</div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div className="mt-2">{renderProfileDetails()}</div>
                    </TabPanel>
                  </TabContext>
                </Box>
              {/* {
                !areObjectsEqual(storeDetails, defaultStoreDetails) && ( */}
              <div className="flex justify-center">
                <Button
                  style={{ marginRight: 10 }}
                  variant="contained"
                  onClick={onUpdate}
                  disabled={!anyChangeInData()}
                >
                  Update Store
                </Button>
              </div>
              {/* )
              } */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProviderDetails;
