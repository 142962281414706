import RenderInput from "../../../../utils/RenderInput";
import { _deliveryFields } from "../product-fields-modified";
import { getProductFieldDetails } from "../utils";

const DeliveryInfoForm = ({ deliveryInfoForm, makeToOrder }) => {
  const { formValues, setFormValues, errors } = deliveryInfoForm;
  const deliveryFieldDetails = makeToOrder
    ? _deliveryFields.map(fieldId=>getProductFieldDetails(fieldId))
    : _deliveryFields.filter(fieldId=>fieldId!="makeToOrderDispatchTime").map(fieldId=>getProductFieldDetails(fieldId))

  return (
    <div>
      {deliveryFieldDetails.map((field) => {
        return (
          <RenderInput
            item={{
              ...field,
              error: errors?.[field?.id] ? true : false,
              helperText: errors?.[field?.id] || "",
            }}
            state={formValues}
            stateHandler={setFormValues}
            key={field.id}
          />
        );
      })}
      <hr className="border-spacing-5" />
    </div>
  );
};

export default DeliveryInfoForm;