import React, { useState } from "react";
import { TextField, CircularProgress, Autocomplete } from "@mui/material";

const GemstoneFields = ({ gemstone, onChange, onRemove, gemstoneFields }) => {
  const [isLoading, setIsLoading] = useState(false); // State to track loading state

  const handleGemstoneChange = (updatedValue, key) => {
    onChange({ ...gemstone, [key]: updatedValue });
  };

  return (
    <div className="md:w-[24rem] mx-[1rem]">
      {gemstoneFields.map(({ id, title, type, options = [], width = "350px", valueInDecimal }) => (
        <div key={id} style={{ marginTop: "1rem" }}>
          {
            type==="select" ? (<Autocomplete
              freeSolo
              value={gemstone[id]}
              options={options.map((option) => option.value)}
              getOptionLabel={(option) => option}
              onChange={(e, newVal) => {
                handleGemstoneChange(newVal, id)
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={title}
                  variant="outlined"
                />
              )}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />) : (<TextField
            label={title}
            value={gemstone[id]}
            onChange={(e) => {
              let value = e.target.value;
              if(!valueInDecimal && value.includes(".")){
                value = value.substring(0, value.length-1)
              }
              handleGemstoneChange(value, id)
            }}
            variant="outlined"
            size="small"
            fullWidth
            type={type === "number" ? "number" : "text"}
            InputLabelProps={{ shrink: true }}
          >
          </TextField>)
          }
          
          {isLoading && (
            <div style={{ margin: "10px" }}>
              <CircularProgress size={24} />
            </div>
          )}
          {/* <div style={{ margin: "10px" }}>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={onRemove}
            >
              <img
                alt="Remove"
                src="https://d30y9cdsu7xlg0.cloudfront.net/png/53504-200.png"
                style={{
                  cursor: "pointer",
                  float: "right",
                  marginTop: "7px",
                  width: "17px",
                }}
              />
            </button>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default GemstoneFields;
