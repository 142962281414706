import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import ProductInfoForm from "./forms/ProductInfoForm";
import VariantsInfoForm from "./forms/VariantsInfoForm";
import DeliveryInfoForm from "./forms/DeliveryInfoForm";
import VitalInfoForm from "./forms/VitalInfoForm";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { getCall, postCall, putCall } from "../../../Api/axios";
import cogoToast from "cogo-toast";
import useForm from "../../../hooks/useForm";
import AddVariants from "./AddVariants";
import { useParams, useNavigate } from "react-router-dom";
import {
  deliveryInfoInitialValues,
  formattedVariantDataForAddProduct,
  getProductFieldDetails,
  productInfoInitialValues,
  vitalInfoInitialValues,
} from "./utils";
import {
  _deliveryFields,
  _diamondFields,
  _makeToOrderFields,
  _productFields,
  _variationFields,
  ecomFields,
  variationCommonFields,
} from "./product-fields-modified";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
} from "@mui/material";
import {
  PRODUCT_SUBCATEGORY,
  FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY,
} from "../../../utils/constants";
import {
  validateDeliveryInfoForm,
  validateProductInfoForm,
  validateVariantsForms,
  validateVitalInfoForm,
} from "./forms/FormValidations";
import useCancellablePromise from "../../../Api/cancelRequest";
import { constructS3Url } from "../../../utils/utilityFunctions";
import { propertyEnums } from "./categoryProperties";

const ProductDetails = (props) => {
  const {
    category,
    subCategory,
    categoryForm,
    selectedVariantNames,
    variants,
    attributes,
    providerDetails,
    productDetails,
    variationOn = "none",
    jewelleryType,
    jewelleryCategory,
    productId
  } = props;
  const navigate = useNavigate();
  const [isAdmin] = useState(()=>{
    const currentUser = JSON.parse(localStorage.getItem("user"));
    return currentUser?.role?.name === "Super Admin";
  })
  const [makeToOrder, setMakeToOrder] = useState();
  const [tabValue, setTabValue] = useState("1");
  const [goldRate, setGoldRate] = useState(0);
  const [silverRate, setSilverRate] = useState(0);
  const [goldSilverRatesFetched, setGoldSilverRatesFetched] = useState(()=>{
    if(jewelleryType==="fashion"){
      return true;
    }
    return false;
  });
  const [city, setCity] = useState("Delhi");
  const [duplicateProductCode, setDuplicateProductCode] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tabErrors, setTabErrors] = useState([false, false, false, false]);
  const [toApprove, setToApprove] = useState(false);
  const [isHSNCode, setIsHSNCode] = useState();
  const isFormUpdated = useRef(false);
  const { cancellablePromise } = useCancellablePromise();
  const theme = useTheme();
  //initialize forms corresponding to each tab
  const productInfoForm = useForm(()=>productInfoInitialValues(!!productId, jewelleryType));
  const deliveryInfoForm = useForm(deliveryInfoInitialValues);
  const vitalInfoForm = useForm(()=>vitalInfoInitialValues(attributes, subCategory, jewelleryType));
  const variantsInfoForm = useForm({});

  const handleTabChange = (e, newTab) => {
    setTabValue(newTab);
  };

  //update forms with product data for edit product
  useEffect(() => {
    //add condition to check if its add-product or edit-product, the code is only for add-product
    if (productDetails && productId) {
      let ecom_data = {};
      ecomFields.forEach((e) => {
        ecom_data[e] = productDetails.commonDetails[e];
        delete productDetails.commonDetails[e];
      });
      let delivery_data = {};
      _deliveryFields.forEach((e) => {
        delivery_data[e] = productDetails.commonDetails[e];
        delete productDetails.commonDetails[e];
      });
      productInfoForm.setFormValues({ ...productDetails.commonDetails });
      deliveryInfoForm.setFormValues({ ...delivery_data });
      vitalInfoForm.setFormValues({
        ...ecom_data,
        ...productDetails.commonAttributesValues,
      });
    }
  }, [productDetails]);

  // Helper function to compare two objects
  const compareProperties = (obj1, obj2) => {
    for (const key in obj1) {
      if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return false; // Mismatch found
        }
      }
    }
    return true; // All matching properties are equal
  };
  useEffect(()=>{
    if(isFormUpdated.current){
      return;
    }

    if(productDetails && productId){
      let ecom_data = {};
      ecomFields.forEach((e) => {
        ecom_data[e] = productDetails.commonDetails[e];
        delete productDetails.commonDetails[e];
      });
      let delivery_data = {};
      _deliveryFields.forEach((e) => {
        delivery_data[e] = productDetails.commonDetails[e];
        delete productDetails.commonDetails[e];
      });

      const productFormUpdated = !compareProperties(
        productInfoForm.formValues,
        productDetails.commonDetails
      );

      const deliveryFormUpdated = !compareProperties(
        deliveryInfoForm.formValues,
        delivery_data
      );

      const vitalFormUpdated = !compareProperties(
        vitalInfoForm.formValues,
        {
          ...ecom_data,
          ...productDetails.commonAttributesValues,
        }
      );

      // If all form's fields are updated, set isFormUpdated to true
      isFormUpdated.current = productFormUpdated && deliveryFormUpdated && vitalFormUpdated;
    }
  }, [productInfoForm.formValues, deliveryInfoForm.formValues, vitalInfoForm.formValues])

  //update forms with default data from store details for add product
  useEffect(() => {
    //add condition to check if its add-product or edit-product, the code is only for add-product
    if (providerDetails) {
      const { storeDetails = {}, name: storeName = "" } = providerDetails;
      const { fulfillments: fulfillmentsArray = [], address = {} } = storeDetails;

      let fulfillments = "delivery";
      if (fulfillmentsArray && fulfillmentsArray.length == 0) {
        fulfillments = "delivery";
      } else if (fulfillmentsArray[0].type === "delivery&pickup") {
        fulfillments = "delivery";
      } else {
        fulfillments = fulfillmentsArray[0].type;
      }
      const fullAddress = `${address.building}, ${address.locality}, ${address.city}, ${address.state}, ${address.country} - ${address.area_code}`;
      deliveryInfoForm.setFormValues((prevValues) => ({
        ...prevValues,
        fulfillmentOption: fulfillments,
        isCancellable: storeDetails.defaultCancellable ? "true" : "false",
        isReturnable: storeDetails.defaultReturnable ? "true" : "false",
      }));
      vitalInfoForm.setFormValues((prev) => ({
        ...prev,
        manufacturerOrPackerAddress: fullAddress,
        manufacturerOrPackerName: storeName,
        Brand: storeName
      }));
    }
  }, [providerDetails]);

  //fetch gold & silver rates
  useEffect(() => {
    if(goldSilverRatesFetched)
      return;
    if(productId && !productDetails)
      return;
    if(!productId && !providerDetails)
      return;
    let isMounted = true;
    const fetchGoldSilverPrice = async () => {
      try {
        // const user = JSON.parse(localStorage.getItem("user"));
        let orgDetailsPromise = null;
        if (productId) {
          console.log("Here is the productId", productId);
          const orgId = productDetails?.commonDetails?.organization;
          console.log("Here is the orgId", orgId);
          orgDetailsPromise = 
              getCall(
                `/api/v1/organizations/${orgId}`
              ).then((resp) => {
                console.log("organization details received:", resp);
                return resp;
              });
          console.log("HERE6 --->>> orgDetailsPromise", orgDetailsPromise);
        }
        // else {
        //   orgDetailsPromise = getCall(
        //     `/api/v1/organizations/${user.organization}`
        //   );
        //   console.log("HERE7 --->>> orgDetailsPromise", orgDetailsPromise);
        // }
        let res;
        if(orgDetailsPromise)
          res = await orgDetailsPromise;
        else
          res = { providerDetail: providerDetails };
        console.log("HERE1 --->>> res", res);
        if (!isMounted) return;
        const cityName = (
          res.providerDetail.storeDetails.address.city || "Nellore"
        ).toLowerCase();
        console.log("Here is the City", cityName);
        setCity(cityName);
        if (goldSilverRatesFetched) return;
        let ratesResponse = await getCall(`/api/v1/goldsilverrates/${cityName}`);
        console.log('ratesRes->', ratesResponse);
        let goldPrice = ratesResponse.data.goldRates.TWENTY_FOUR;
        let silverPrice = ratesResponse.data.silverRates.ONE_HUNDRED_POINT_ZERO;
        setGoldRate(goldPrice);
        setSilverRate(silverPrice);
        setGoldSilverRatesFetched(true);
        console.log("Gold Rate Fetched", goldPrice);
        console.log("Silver Rate Fetched", silverPrice);
        cogoToast.success("Gold & Silver Rates Fetched");
        // return goldPrice;
      } catch (error) {
        if (!isMounted || goldSilverRatesFetched) return;
        cogoToast.warn("Error fetching gold/silver rate try after some time");
        console.error("Error fetching gold/silver rate try after some time", error);
        return error;
      }
    };
    fetchGoldSilverPrice();
    return () => {
      isMounted = false;
    };
  }, [productDetails, providerDetails, goldSilverRatesFetched]);

  //update make to order state
  useEffect(() => {
    if (productInfoForm.formValues.makeToOrder === "true")
      setMakeToOrder(true);
    else
      setMakeToOrder(false);
  }, [productInfoForm.formValues.makeToOrder]);

  useEffect(() => {
    if (duplicateProductCode) {
      const newErrors = { ...productInfoForm.errors };
      delete newErrors.productCode;
      console.log("newErrors->", newErrors);
      productInfoForm.setErrors(newErrors);
    }
  }, [duplicateProductCode]);

  // Check if HSNCode default initial value is empty or not.
  useEffect(()=>{
    if(vitalInfoForm.formValues.HSNCode)
      setIsHSNCode(true);
    else
      setIsHSNCode(false);
  },[]);

  //set default values for product info
  const gender = useMemo(() => {
    return (
      jewelleryCategory === "men"
      ? "MALE"
      : jewelleryCategory === "women"
        ? "FEMALE"
        : "UNISEX"
    )
  }, [jewelleryCategory]);

  const color = useMemo(() => {
    return jewelleryType === "diamond" || jewelleryType === "gold"
      ? "#FFD700"
      : "#C0C0C0";
  }, [jewelleryType]);

  const baseMetal = useMemo(() => {
    return jewelleryType === "diamond" || jewelleryType === "gold"
      ? "GOLD"
      : "SILVER";
  }, [jewelleryType]);

  const platingValue = useMemo(() => {
    return ["yellow", "rose", "white"].includes(productInfoForm.formValues.Finishing)
      ? "GOLD"
      : "SILVER";
  }, [productInfoForm.formValues.Finishing]);

  useEffect(() => {
    let updatedVitalForm = {}
    updatedVitalForm["Gender"] = gender;
    updatedVitalForm["Colour"] = color;
    updatedVitalForm["Base Metal"] = baseMetal;
    updatedVitalForm["Plating"] = platingValue;

    vitalInfoForm.setFormValues((prev) => ({
      ...prev,
      ...updatedVitalForm,
    }));
  }, [productInfoForm.formValues.Finishing])

  useEffect(()=> {
    const colour = vitalInfoForm.formValues.Colour;
    vitalInfoForm.setFormValues((prev) => ({
      ...prev,
      "Colour Name": propertyEnums.color.find((c) => c.value === colour)?.key,
    }));
  }, [vitalInfoForm.formValues.Colour])

  //wait for data and submit
  const waitForData = () => {
    setLoading(true);
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (goldSilverRatesFetched && isSubmitting) {
      setLoading(true);
      handleSubmit();
    }
  }, [goldSilverRatesFetched, isSubmitting]);

  const handleSubmit = async () => {
    const isUpdating = productId ? true : false;
    const isValid = isUpdating ? validateUpdation() : validate();
    if (isValid) {
      setLoading(true);
      if (isUpdating)
        await updateProduct();
      else
        await addProduct();
      setLoading(false);
      setIsSubmitting(false);
      setToApprove(false);
      navigate(-1);
    } else {
      cogoToast.error("Please fill in all required fields appropriately!");
      setLoading(false);
      setIsSubmitting(false);
      setToApprove(false);
    }
  };

  const validate = () => {
    // console.log("At validate.")
    let { product_info_form_errors, product_info_form_validity } =
      validateProductInfoForm(
        productInfoForm.formValues,
        jewelleryType,
        variationOn,
        makeToOrder
      );
    productInfoForm.setErrors(product_info_form_errors);

    let { delivery_info_form_errors, delivery_info_form_validity } = validateDeliveryInfoForm(deliveryInfoForm.formValues, makeToOrder);
    deliveryInfoForm.setErrors(delivery_info_form_errors);

    let { vital_info_form_errors, vital_info_form_validity } = validateVitalInfoForm(vitalInfoForm.formValues);
    vitalInfoForm.setErrors(vital_info_form_errors);

    let { variants_info_form_errors, variants_info_form_validity } =
      validateVariantsForms(
        null,
        variantsInfoForm.formValues,
        variationOn,
      );
    variantsInfoForm.setErrors(variants_info_form_errors);

    // let customization_details_validity = validateCustomizationDetails();

    setTabErrors((prev_state) => {
      prev_state[0] = !product_info_form_validity;
      prev_state[1] = !variants_info_form_validity;
      prev_state[2] = !delivery_info_form_validity;
      prev_state[3] = !vital_info_form_validity;
      // prev_state[3] = !customization_details_validity;
      return [...prev_state];
    });
    console.log("At validate Tab errors : ", product_info_form_validity ,"and",delivery_info_form_validity ,"and", vital_info_form_validity,"and", variants_info_form_validity );
    let result =
      variants_info_form_validity &&
      product_info_form_validity &&
      vital_info_form_validity &&
      delivery_info_form_validity;
    // customization_details_validity;
    // console.log("At validate result : ", result);;
    return result;
  };

  const validateUpdation = () => {
    let { product_info_form_errors, product_info_form_validity } =
      validateProductInfoForm(
        productInfoForm.formValues,
        jewelleryType,
        variationOn,
        makeToOrder
      );
    productInfoForm.setErrors(product_info_form_errors);

    let { delivery_info_form_errors, delivery_info_form_validity } = validateDeliveryInfoForm(deliveryInfoForm.formValues, makeToOrder);
    deliveryInfoForm.setErrors(delivery_info_form_errors);

    let { vital_info_form_errors, vital_info_form_validity } = validateVitalInfoForm(vitalInfoForm.formValues);
    vitalInfoForm.setErrors(vital_info_form_errors);

    // let variants_forms_validity = validateVariantsForms();
    // let customization_details_validity = validateCustomizationDetails();
    setTabErrors((prev_state) => {
      prev_state[0] = !product_info_form_validity;
      // prev_state[1] = !variants_forms_validity;
      prev_state[2] = !delivery_info_form_validity;
      prev_state[3] = !vital_info_form_validity;
      // prev_state[3] = !customization_details_validity;
      return [...prev_state];
    });
    console.log("At validateUpdation Tab errors : ", product_info_form_validity ,"and",delivery_info_form_validity ,"and", vital_info_form_validity);
    let result =
      // variants_forms_validity &&
      product_info_form_validity && vital_info_form_validity && delivery_info_form_validity;
    // customization_details_validity;
    console.log("At validateUpdation result : ", result);
    return result;
  };

  //TODO:- remove empty fields that are not required for the product of any type before adding to either dbs
  const addProduct = async () => {
      console.log("At add product");
      try {
        let product_data = Object.assign({}, productInfoForm.formValues, categoryForm.formValues);
        let vital_data = Object.assign({}, vitalInfoForm.formValues);
        let delivery_data = Object.assign({}, deliveryInfoForm.formValues);
        let ecom_data = {}
        ecomFields.forEach((e) => {
          ecom_data[e] = vital_data[e];
          delete vital_data[e];
        })
        console.log("product_data :", product_data, " delivery_data :", delivery_data, " vital_data :", vital_data, " ecom_data :", ecom_data);
        product_data = { ...product_data, ...delivery_data, ...ecom_data };
        let variant_data = formattedVariantDataForAddProduct([], variationOn, selectedVariantNames);
        let api_url = "/api/v1/SellerProducts";
        const subCatList =
          PRODUCT_SUBCATEGORY[categoryForm.formValues?.productCategory];
        const selectedSubCatObject = subCatList.find(
          (subitem) =>
            subitem.value === categoryForm.formValues?.productSubcategory1
        );
        if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
          const hiddenFields =
            FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
            selectedSubCatObject.protocolKey
            ];
          hiddenFields.forEach((field) => {
            delete product_data[field];
          });
        } else {
        }
        if (variationOn !== "none") {
          variationCommonFields.forEach((field) => {
            delete product_data[field];
          });
        }
        // Create a duration object with the hours you want to convert
        const duration = moment.duration(parseInt(product_data.returnWindow), "days");
        // Format the duration in ISO 8601 format
        const iso8601 = duration.toISOString();
        product_data.returnWindow = iso8601;
        product_data.huidDone =
          product_data.huidDone === "true" ? true : false;
        product_data.hallmarkDone =
          product_data.hallmarkDone === "true" ? true : false;
        product_data.isCancellable =
          product_data.isCancellable === "true" ? true : false;
        product_data.isReturnable =
          product_data.isReturnable === "true" ? true : false;
        // product_data.isVegetarian = product_data.isVegetarian === "true" ? true : false;
        // product_data.availableOnCod =
        //   product_data.availableOnCod === "true" ? true : false;
        product_data.productSubcategory2 = jewelleryType;
        product_data.productSubcategory3 = jewelleryCategory;
        delete product_data["uploaded_urls"];
        delete vital_data["tempURL"];
        if(product_data.makeToOrder==="false"){
          delete product_data["makeToOrderMaxPrice"];
          delete product_data["makeToOrderMaxSellingPrice"];
          delete product_data["makeToOrderDispatchTime"];
          delete product_data["net_weight_range"];
        }
        let data = {
          commonDetails: product_data,  // pri + del
          commonAttributesValues: vital_data, // vital + ecom
        };
        if (variationOn !== "none") {
          data["variantSpecificDetails"] = variant_data;
          data["variationOn"] = variationOn?.toUpperCase();
        }
        if (variationOn !== "none") {
          api_url = "/api/v1/SellerProductsWithVariation"
          // api_url = "/api/v1/productWithVariant"
          // data = prodWithVarDataManupulation(data);
        }
        console.log("This is api and data on add btn click : ",api_url, data);
        await cancellablePromise(postCall(api_url, data));
        cogoToast.success("Product added successfully!");
      } catch (error) {
        console.log("Addbtn error : " ,error);
      }
  };

  const updateProduct = async () => {
      console.log("At update Btn.")
      try {
        let product_data = Object.assign({}, productInfoForm.formValues);
        let vital_data = Object.assign({}, vitalInfoForm.formValues);
        let delivery_data = Object.assign({}, deliveryInfoForm.formValues)
        let ecom_data = {};
        ecomFields.forEach((e) => {
          ecom_data[e] = vital_data[e];
          delete vital_data[e];
        });
        product_data = { ...product_data, ...delivery_data, ...ecom_data };
        const subCatList = PRODUCT_SUBCATEGORY[category];
        const selectedSubCatObject = subCatList.find(
          (subitem) => subitem.value === productInfoForm.formValues.productSubcategory1
        );
        if (selectedSubCatObject && selectedSubCatObject.protocolKey) {
          const hiddenFields =
            FIELD_NOT_ALLOWED_BASED_ON_PROTOCOL_KEY[
            selectedSubCatObject.protocolKey
            ];
          hiddenFields.forEach((field) => {
            delete product_data[field];
          });
        } else {
        }
        const duration = moment.duration(parseInt(product_data.returnWindow), "days");
        const iso8601 = duration.toISOString();
        product_data.returnWindow = iso8601;
        product_data.huidDone =
          product_data.huidDone === "true" ? true : false;
        product_data.isCancellable =
          product_data.isCancellable === "true" ? true : false;
        product_data.isReturnable =
          product_data.isReturnable === "true" ? true : false;
        // product_data.isVegetarian = product_data.isVegetarian === "true" ? true : false;
        // product_data.availableOnCod =
        //   product_data.availableOnCod === "true" ? true : false;
        let fields_to_remove = [
          "__v",
          // "organization",
          "createdAt",
          "updatedAt",
          "published",
          "uploaded_urls",
          "createdBy",
          "_id",
          "variantGroup",
        ];
        fields_to_remove.forEach((field) => {
          delete product_data[field];
        });
        delete vital_data["tempURL"];
        if(product_data.makeToOrder==="false"){
          delete product_data["makeToOrderMaxPrice"];
          delete product_data["makeToOrderMaxSellingPrice"];
          delete product_data["makeToOrderDispatchTime"];
          delete product_data["net_weight_range"];
        }
        let data = {
          commonDetails: product_data,
          commonAttributesValues: vital_data,
          // customizationDetails: {
          //   customizationGroups,
          //   customizations,
          // },
        };
        console.log("Update btn data:", data);
        await putCall(`/api/v1/SellerProducts/${productId}`, data);
        cogoToast.success(`Product updated successfully!${toApprove ? ' Approving...' : ''}`);
        toApprove && await handleApproveQC(data);
      } catch (error) {
        console.log(error);
      }
  };

  const handleApproveQC = async(data) => {
    // add product id to data
    data.commonDetails._id = productId;
    if (data.commonDetails?.images && data.commonDetails.images?.length > 0) {
      let images = [];
      for (const img_path of data.commonDetails.images) {
        let data = await constructS3Url(img_path);
        images.push(data);
      }
      data.commonDetails.images = images;
    }
    if (data.commonDetails?.certifications && data.commonDetails.certifications?.length > 0) {
      let images = [];
      for (const img_path of data.commonDetails.certifications) {
        let data = await constructS3Url(img_path);
        images.push(data);
      }
      data.commonDetails.certifications = images;
    }

    const fieldMapping = {
      totalSellingPrice: "MRP",
      sellerPrice: "sellerPrice",
      sellerPrice: "purchasePrice",
      10: "maxAllowedQty",
      _id: "relation",
    };

    const fieldsToRemove = [
        "_id", "__v", "published", "updatedAt", "QCStatus", "QCDesc",
        "huidNumber", "huidDone", "hallmarkDone", "Finishing", "wastageOnGold", "net_weight",
        "net_weight_range", "diamonds_weight", "diamondColour",
        "diamondClarity", "diamondPrice", "Purity", "diamondCostPerCt", "pricingType", "makingChargesType",
        "makingCharges", "certifications", "gemstones", "gemstonePrice",
        "productSubcategory2", "productSubcategory3", "sellerBasePrice"
    ];
    Object.keys(fieldMapping).forEach((oldField) => {
        const newField = fieldMapping[oldField];
        if (data.commonDetails.hasOwnProperty(oldField)) {
            data.commonDetails[newField] = data.commonDetails[oldField];
            delete data.commonDetails[oldField];
        }
    });

    fieldsToRemove.forEach((field) => {
        delete data.commonDetails[field];
    });

    let data2 = {
        QCStatus: "Approved",
    };

    return postCall(`/api/v1/products?_id=${productId}`, data)
      .then(async() => {
          cogoToast.success("Product Pushed to Main DB!");
          return putCall(`/api/v1/products/${productId}/QC`, data2)
            .then(() => {
                cogoToast.success("Product QC Status Updated");
            });
        });
  };

  // TODO:- Fix the Update and Approve button
  //        - QCStatus remains "In Progress" on clicking this button for a newly added product but works from second time onwards

  return (
    <form>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <TabList
              value={tabValue}
              onChange={handleTabChange}
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor:
                    tabErrors[tabValue - 1]
                      ? "red"
                      : theme.palette.tertiary.main,
                },
                "& .MuiTab-root.Mui-selected": {
                  color:
                    tabErrors[tabValue - 1]
                      ? "red"
                      : theme.palette.tertiary.main,
                  fontWeight: 700,
                },
              }}
              variant="scrollable"
            >
              <Tab
                sx={{
                  color:
                    tabErrors[0]
                      ? "red"
                      : "none",
                  minWidth: "150px",
                }}
                label={variationOn !== "none" ? "General Info" : "Product Info"}
                value="1"
              />
              {variationOn !== "none" && (
                <Tab
                  sx={{
                    color:
                      tabErrors[1]
                        ? "red"
                        : "none",
                    minWidth: "150px",
                  }}
                  label="Variants Info"
                  value="2"
                />
              )}
              <Tab
                sx={{
                  color:
                    tabErrors[2]
                      ? "red"
                      : "none",
                  minWidth: "150px",
                }}
                label="Delivery Info"
                value="3"
              />
              <Tab
                sx={{
                  color:
                    tabErrors[3]
                      ? "red"
                      : "none",
                  minWidth: "150px",
                }}
                label="Vital Info"
                value="4"
              />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ProductInfoForm
              productId={productId}
              productInfoForm={productInfoForm}
              category={category}
              subCategory={subCategory}
              jewelleryType={jewelleryType}
              variationOn={variationOn}
              goldRate={goldRate}
              silverRate={silverRate}
              duplicateProductCode={duplicateProductCode}
              handleDuplicateProductCode={(val)=>setDuplicateProductCode(val)}
              isAdmin={isAdmin}
              makeToOrder={makeToOrder}
              HSNCode={vitalInfoForm.formValues.HSNCode}
              isHSNCode={isHSNCode}
              isFormUpdated={isFormUpdated.current}
            />
          </TabPanel>
          <TabPanel value="2">
            <AddVariants
              variantsInfoForm={variantsInfoForm}
              generalInfoValues={productInfoForm.formValues}
              goldRate={goldRate}
              silverRate={silverRate}
            />
          </TabPanel>
          <TabPanel value="3">
            <DeliveryInfoForm
              deliveryInfoForm={deliveryInfoForm}
              makeToOrder={makeToOrder}
            />
          </TabPanel>
          <TabPanel value="4">
            <VitalInfoForm
              vitalInfoForm={vitalInfoForm}
              attributes={attributes}
              isHSNCode={isHSNCode}
            />
          </TabPanel>
        </TabContext>
      </Box>

      <div className="flex flex-row justify-center py-2">
        <Button
          type="button"
          variant="contained"
          className="!bg-[#ebe4dc] !text-black"
          onClick={() => {
            if (duplicateProductCode) setShowDuplicateDialog(true);
            else !goldSilverRatesFetched ? waitForData() : setIsSubmitting(true);
          }}
          disabled={loading}
        >
          {loading && !toApprove && (
            <CircularProgress color="tertiary" size={20} className="!mr-2" />
          )}
          {productId ? "Update Product" : "Add Product"}
        </Button>
      </div>
      {isAdmin && <div className="flex flex-row justify-center pt-1">
        <Button
          type="button"
          variant="contained"
          className="!bg-[#ebe4dc] !text-black"
          onClick={() => {
            setToApprove(true);
            if (duplicateProductCode) setShowDuplicateDialog(true);
            else !goldSilverRatesFetched ? waitForData() : setIsSubmitting(true);
          }}
          disabled={loading}
        >
          {loading && toApprove && (
            <CircularProgress color="tertiary" size={20} className="!mr-2" />
          )}
          Update and Approve Product
        </Button>
      </div>}
      <Dialog
        open={showDuplicateDialog}
        onClose={() => setShowDuplicateDialog(false)}
      >
        <DialogTitle>Duplicate Product Number Found</DialogTitle>
        <DialogContent>
          {`A product with the product no. (${productInfoForm.formValues.productCode}) already exists. Are you sure you want to use the same product no.?`}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowDuplicateDialog(false)}
            color="tertiary"
          >
            No
          </Button>
          <Button
            onClick={() => {
              setShowDuplicateDialog(false);
              !goldSilverRatesFetched ? waitForData() : setIsSubmitting(true);
            }}
            color="tertiary"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default ProductDetails;