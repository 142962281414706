//TODO: This Inventory.jsx corresponds to Provider/Seller View, fix the file name, also for InventoryTable.
import { useEffect, useRef, useState} from "react";
import InventoryTable from "../Inventory/InventoryTable";
import Button from "../../Shared/Button";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { getCall } from "../../../Api/axios";
import useCancellablePromise from "../../../Api/cancelRequest";
import { isObjEmpty } from "../../../utils/validations";
import { PRODUCT_CATEGORY } from "../../../utils/constants";
import { useTheme } from "@mui/material/styles";
import FilterComponent from "../../Shared/FilterComponent";
import cogoToast from "cogo-toast";
import { loadingView } from "../../Shared/LoadingView";


const columns = [
  {
    id: "productSubcategory2",
    label: "Product Type",
    minWidth: 120,
    // format: (value) => PRODUCT_CATEGORY[value] || value,
  },
  { id: "productName", label: "Product Name", minWidth: 250 },
  {id:"productCode",label:"Product Number",minWidth:100},
  {
    id: "makeToOrder",
    label: "Make-To-Order (Y/N)?",
    minWidth: 150,
    format: (value) => value?.toLocaleString("en-US"),
  },
  {
    id: "createdAt",
    label: "Created At",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "quantity",
    label: "Quantity",
    minWidth: 100,
    format: (value) => value.toLocaleString("en-US"),
  },
 
  // {
  //   id: "isCancellable",
  //   label: "Cancellable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  // {
  //   id: "isReturnable",
  //   label: "Returnable",
  //   boolean: true,
  //   minWidth: 100,
  // },
  {
    id: "QCStatus",
    label: "QC Status",
    minWidth: 100,
  },
  {
    id: "QCDesc",
    label: "QC Comments",
    minWidth: 150,
  },

  {
    id: "published",
    label: "Published",
  },
];

export default function Inventory() {
  const theme = useTheme();
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("inventorytablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("inventorytablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState();
  const navigate = useNavigate();
  const { cancellablePromise } = useCancellablePromise();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState(()=>{
    const savedFilters = sessionStorage.getItem("filters");
    return savedFilters ? JSON.parse(savedFilters) : {
      name: null,
      number: null,
      provider_store: null,
      category: "",
      stock: false,
      QCStatus:[],
      published:[]
    }
  });
  const [filterQuery, setFilterQuery] = useState(()=>{
    const savedQuery = sessionStorage.getItem("filterQuery");
    return savedQuery;
  });
  const [nameFilterOptions, setNameFilterOptions] = useState([]);
  const [numberFilterOptions, setNumberFilterOptions] = useState([]);
  const isInitialMount = useRef(true);

  const filterFields = [
    {
      id: "name",
      title: "",
      placeholder: "Search by Product Name",
      type: "select-with-fetch",
      options: nameFilterOptions,
      variant: "standard",
    },
    {
      id: "number",
      title: "",
      placeholder: "Search by Product Number",
      type: "select-with-fetch",
      options: numberFilterOptions,
      variant: "standard",
    },
    // {
    //   id: "category",
    //   title: "",
    //   placeholder: "Please Select Product Category",
    //   options: Object.entries(PRODUCT_CATEGORY).map(([key, value]) => {
    //     return { key: value, value: key };
    //   }),
    //   type: "select",
    //   variant: "standard",
    //   disableClearable: true,
    // },
    {
      id: "QCStatus",
      title: "",
      placeholder: "QC Status",
      options: [
        {key:"In Progress",value:"In Progress"},
        {key:"Approved",value:"Approved"},
        {key:"Rejected",value:"Rejected"},
      ],
      
      type: "select-multiple",
      variant: "standard",
      minWidth: "15rem",
      // disableClearable: true,
      multiple: true
    },
    // {
    //   id: "stock",
    //   title: "Out of Stock",
    //   placeholder: "Please Select Product Category",
    //   type: "switch",
    //   containerClasses: "flex items-center",
    //   styles: {
    //     marginLeft: 2,
    //   },
    // },
    {
      id: "published",
      title: "",
      placeholder: "Published",
      type: "select-multiple",
      options: [
        {key:"Yes", value:"true"},
        {key:"No", value:"false"}
      ],
      variant: "standard",
      minWidth: "15rem",
      // disableClearable: true,
      multiple: true
    },
  ];

  const getProducts = async () => {
    try {
      const res = await cancellablePromise(getCall(`/api/v1/SellerProducts?${filterQuery}&limit=${rowsPerPage}&offset=${page}`));
      setProducts(res.data);
      setTotalRecords(res.count);
      setLoading(false);
    } catch (error) {
      cogoToast.error("Something went wrong!");
      setLoading(false);
    }
  };

  const getOrgDetails = async (org_id) => {
    const url = `/api/v1/organizations/${org_id}/storeDetails`;
    const res = await getCall(url);
    return res;
  };

  const getUser = async (id) => {
    const url = `/api/v1/users/${id}`;
    const res = await getCall(url);
    return res[0];
  };

  useEffect(() => {
    const user_id = localStorage.getItem("user_id");
    getUser(user_id).then((u) => {
      // roles - Organization Admin, Super Admin
      //TODO:- Remove the system generated password feature
      //       - Ask new users for password only at the initial signup
      //       - Move all password validations from ProvidderInitialSteps.js to addProvidermodified.js
      //       - Remove the code in user.service.js file to generate random password and throw an error is password is not provided
      if (u.isSystemGeneratedPassword) navigate("/initial-steps");
      else {
        if (u.role.name == "Organization Admin") {
          getOrgDetails(u.organization).then((org) => {
            if (isObjEmpty(org.storeDetails)) navigate("/initial-steps");
          });
        } else navigate("/product-listings");
      }
    });
  }, []);

  useEffect(()=>{
    if(totalRecords){
      const maxPage = Math.ceil(totalRecords / rowsPerPage);
      if(page >= maxPage){
        handlePageChange(maxPage-1);
      }
    }
  }, [totalRecords]);

  useEffect(() => {
    setLoading(true);
    getProducts();
  }, [page, rowsPerPage]);

  const handleRefresh = (data) => {
    getProducts();
  };

  useEffect(()=>{
    sessionStorage.setItem("filters", JSON.stringify(filters));
    onFilter();
    if(!filters.name)
      setNameFilterOptions([]);
    if(!filters.number)
      setNumberFilterOptions([]);
  }, [filters]);

  useEffect(()=>{
    if(isInitialMount.current){
      isInitialMount.current = false;
    } else{
      if(filterQuery || filterQuery === ""){
        sessionStorage.setItem("filterQuery", filterQuery);
        if(page === 0){
          setLoading(true);
          getProducts();
        }
        else
          handlePageChange(0);
      }
    }
}, [filterQuery]);

  const fetchOptions = async(search_text, property)=> {
    if(search_text?.length>0){
      const res = await cancellablePromise(getCall(`/api/v1/SellerProducts?${property}=${encodeURIComponent(search_text)}&published=[true,false]`));
      if(property==='name')
        setNameFilterOptions([...new Set(
          res.data.map((item)=>(
          item.productName)))]);
      else if(property==='number')
        setNumberFilterOptions([...new Set(
          res.data.map((item)=>(
          item.productCode)))]);
    } else {
      property === 'name' && setNameFilterOptions([]);
      property === 'number' && setNumberFilterOptions([]);
    }
  }

  const onReset = () => {
    setFilters({ name: null, number: null, category: null, stock: false, QCStatus: [], published: [] });
    setNameFilterOptions([]);
    setNumberFilterOptions([]);
    // getProducts();
  };

  //TODO: Fix Pagination to work with filters
  //IssueDescriptor: 
  // 1) on adding filters when current page in table is 2 or higher,
  //    the page data in PaginationSection doesnt change and shows older(no longer valid) info.
  // 2) all items returned from filter are shown at once,
  //    instead they should also be paginated.
  // 3) on changing the page after applying filters,
  //    filters are removed and all-items get call is made
  //    as per older(saved in sessionStorage) page details.
  const onFilter = async () => {
    const filterParams = [];
    if (filters.name != undefined && filters.name?.trim() !== "") {
      filterParams.push(`name=${encodeURIComponent(filters.name)}`);
    }

    if (filters.number != undefined && filters.number !== "") {
      filterParams.push(`number=${encodeURIComponent(filters.number)}`);
    }

    if (filters.category != undefined && filters.category !== "") {
      filterParams.push(`category=${encodeURIComponent(filters.category)}`);
    }

    // if (!filters.stock) {
    //   filterParams.push(`stock=inStock`);
    // } else {
    //   filterParams.push(`stock=outOfStock`);
    // }

    if(filters.QCStatus.length>0){
      console.log('status', filters.QCStatus)
      filterParams.push(`QCStatus=${JSON.stringify(filters.QCStatus.map(item=>item.value))}`);
    }

    if(filters.published.length>0){
      filterParams.push(`published=${JSON.stringify(filters.published.map(item=>item.value))}`);
    }

    const queryString = filterParams.join("&");
    // const url = `/api/v1/SellerProducts?${queryString}`;
    // const res = await cancellablePromise(getCall(url));
    // setProducts(res.data);
    // setTotalRecords(res.count);
    setFilterQuery(queryString);
  };

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("inventorytablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("inventorytablerowsperpage", val);
  }

  return (
    <>
      { loading ? loadingView : ( <div className="container mx-auto my-8 px-2 md:px-8">
        <div className="mb-4 flex flex-row justify-between items-center">
          <label style={{ color: theme.palette.text.primary }} className="font-semibold text-2xl">
            Inventory
          </label>
          <div className="flex">
            {/* <div style={{ marginRight: 15 }}>
              <Button
                variant="contained"
                icon={<AddIcon />}
                title="Bulk upload"
                onClick={() => navigate("/application/bulk-upload")}
              />
            </div> */}
            <Button
              variant="contained"
              icon={<AddIcon />}
              className=""
              title="ADD PRODUCT"
              onClick={() => navigate("/application/add-products")}
            />
          </div>
        </div>
        <FilterComponent
          fields={filterFields}
          state={filters}
          stateHandler={setFilters}
          onReset={onReset}
          onFilter={onFilter}
          fetchOptions={fetchOptions}
        />
        <InventoryTable
          columns={columns}
          data={products}
          onRefresh={handleRefresh}
          totalRecords={totalRecords}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div> )}
    </>
  );
}