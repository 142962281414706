import MyButton from "../../Shared/Button";
// import VariantForm from "./VariantForm";
import { v4 as uuidv4 } from "uuid";
import { useTheme } from "@mui/material";
import { useState } from "react";
import VariantsInfoForm from "./forms/VariantsInfoForm";

const AddVariants = ({
  // variantFields,
  // variantInitialValues,
  // variantForms,
  // setVariantForms,
  variantsInfoForm,
  // shouldValidate,
  // variantFormsErrors,
  generalInfoValues,
  goldRate
}) => {
  const theme = useTheme();
  const [variantForms, setVariantForms] = useState([{...variantsInfoForm, formKey: uuidv4()}]);
  console.log('variant forms data: ', variantForms);

  // const handleOnVariantFormUpdate = (index, formValues) => {
    //   variantForms[index] = formValues;
    //   setVariantForms([...variantForms]);
    // };

  const handleAddForm = () => {
    setVariantForms([...variantForms, { ...variantsInfoForm, formKey: uuidv4() }]);
  };
  const handleRemoveForm = (i) => {
    variantForms.splice(i, 1);
    setVariantForms([...variantForms]);
    // variantFormsErrors.splice(i, 1);
  };

  const renderForms = () => {
    return variantForms.map((form, i) => {
      return (
        <VariantsInfoForm
          key={form.formKey}
          index={i}
          variantsInfoForm={form}
          // fields={variantFields}
          // onFormUpdate={handleOnVariantFormUpdate}
          // shouldValidate={shouldValidate}
          // formsErrors={variantFormsErrors}
          removeForm={handleRemoveForm}
          generalInfoValues={generalInfoValues}
          goldRate={goldRate}
        />
      );
    });
  };

  return (
    <>
      {renderForms()}
      <MyButton type="button" title="Add Variation" className="!text-lg !font-bold !tracking-wide !text-[#b47437] !underline" onClick={() => handleAddForm()}/>
    </>
  );
};

export default AddVariants;
