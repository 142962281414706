import { useEffect, useState, useMemo } from "react";
import MyButton from "../../Shared/Button";
import cogoToast from "cogo-toast";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCall } from "../../../Api/axios";
import BackNavigationButton from "../../Shared/BackNavigationButton";
import { allProperties } from "./categoryProperties";
import ProductDetails from "./ProductDetails";

export default function EditProduct() {
  const [productDetails, setProductDetails] = useState();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id: productId } = useParams();

  const getProduct = () => {
    getCall(`/api/v1/SellerProducts/${productId}`)
      .then((resp) => {
        console.log("Response of getProduct: ", resp);
        resp.commonDetails["uploaded_urls"] = {
          images: resp?.commonDetails.images?.map((i) => i?.url) || [],
          certifications: resp?.commonDetails.certifications?.map((i) => i?.url) || [],
        }
        resp.commonDetails["images"] =
          resp?.commonDetails.images?.map((i) => i?.path) || [];
        resp.commonDetails["certifications"] =
          resp?.commonDetails.certifications?.map((i) => i) || [];
        resp.commonDetails.huidDone = resp.commonDetails.huidDone
          ? "true"
          : "false";
        resp.commonDetails.hallmarkDone = resp.commonDetails.hallmarkDone
          ? "true"
          : "false";
        resp.commonDetails.isCancellable = resp.commonDetails.isCancellable
          ? "true"
          : "false";
        resp.commonDetails.isReturnable = resp.commonDetails.isReturnable
          ? "true"
          : "false";
        // resp.commonDetails.isVegetarian = resp.commonDetails.isVegetarian
        //   ? "true"
        //   : "false";
        // resp.commonDetails.availableOnCod = resp.commonDetails.availableOnCod
        //   ? "true"
        //   : "false";
        if (!resp.commonDetails.makeToOrder) {
          resp.commonDetails.makeToOrder = "false";
        }
        if (resp.commonAttributesValues["size_chart"]) {
          resp.commonAttributesValues["size_chart"] =
            resp.commonAttributesValues["size_chart"].url;
        }
        const duration = moment.duration(resp.returnWindow);
        const days = duration.asDays();
        const returnWindow = resp.commonDetails.returnWindow;
        const returnWindowPattern = /P(\d+)D/;
        const durationMatch = returnWindow.match(returnWindowPattern);
        let finalReturnWindow = 0;
        if (durationMatch) {
          finalReturnWindow = parseInt(durationMatch[1]);
        }
        console.log("Here is the return Window :", finalReturnWindow);
        resp.commonDetails.returnWindow = finalReturnWindow;
        console.log("updated product details->", resp);
        setProductDetails(resp);
      })
      .catch((error) => {
        cogoToast.error("Failed to load product details!")
        console.log(error);
      });
  };

  let category_data = allProperties[state?.productCategory];
  let properties = category_data?.hasOwnProperty(state?.productSubCategory)
    ? category_data[state?.productSubCategory]
    : category_data["default"] || [];

  //fetch product details from api
  useEffect(() => {
    getProduct();
  }, []);

  return (
    <>
      <div className="container mx-auto my-8 px-2 md:px-8 pb-6">
        <div
          className="w-full bg-white px-4 py-4 rounded-md h-full scrollbar-hidden"
          style={{ minHeight: "95%", maxHeight: "100%", overflow: "auto" }}
        >
          <BackNavigationButton onClick={() => navigate(-1)} />
          <div className="w-full !h-full">
            <label className="ml-2 md:mb-4 md:mt-3 mt-2 font-semibold text-xl">
              Update Product
            </label>
            <ProductDetails
              productId={productId}
              productDetails={productDetails}
              category={state?.productCategory}
              subCategory={state?.productSubCategory}
              jewelleryType={state?.jewelleryType}
              jewelleryCategory={state?.jewelleryCategory}
              attributes={properties}
            />
            <div className="flex flex-row justify-center items-center pt-3 gap-4">
              <MyButton
                type="button"
                title="CANCEL"
                size="small"
                className="!text-black !w-20 !bg-[#ebe4dc]"
                variant="contained"
                onClick={() => navigate(-1)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}