import { useState, useEffect } from "react";
import Navbar from "../../Shared/Navbar";
import OrderTable from "../Order/OrderTable";
import { OrderData } from "../../../Constants/OrdersData";
import useCancellablePromise from "../../../Api/cancelRequest";
import { getCall } from "../../../Api/axios";
import { useTheme } from "@mui/material/styles";
import { loadingView } from "../../Shared/LoadingView";

const columns = [
  {
    id: "provider_name",
    label: "Provider Store Name",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 170,
    align: "center",
  },
  { id: "orderId", label: "Order Id", minWidth: 120, align: "center" },
  {
    id: "productNames",
    label: "Product Name(s)",
    minWidth: 250,
    align: "center",
  },
  {
    id: "order_items",
    label: "Items Ordered",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 100,
    align: "center",
  },
  {
    id: "productNumber",
    label: "Product Number(s)",
    minWidth: 120,
    align: "center",
  },
  {
    id: "createdAt",
    label: "Created On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "state",
    label: "Status",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 140,
    align: "center",
  },
  {
    id: "total_amt",
    label: "Total Amount",
    minWidth: 120,
    align: "center",
  },
  {
    id: "payment_type",
    label: "Payment Type",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 170,
    align: "center",
  },
  {
    id: "delivery_info",
    label: "Delivering To",
    format: (value) => value.toLocaleString("en-US"),
    minWidth: 90,
    align: "left",
  },
  {
    id: "updatedAt",
    label: "Last Updated On",
    minWidth: 180,
    format: (value) => value.toLocaleString("en-US"),
    align: "center",
  },
  {
    id: "refundAmount",
    label: "Refund Amount",
    minWidth: 90,
    align: "center",
  },
  {
    id: "sellerSettlementStatus",
    label: "Seller Settlement Status",
    minWidth: 90,
    align: "center",
  },
  {
    id: "downloadAurikaInvoice",
    label: "Invoice to Seller from Aurika",
    minWidth: 180,
    align: "center",
  },
];

export default function Orders() {
  const theme = useTheme();
  const { cancellablePromise } = useCancellablePromise();
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("ordertablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("ordertablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [columnList, setColumnList] = useState(columns);
  const [loading, setLoading] = useState(true);

  // Function to get Orders based on API base URL
  const getOrders = (baseURL) => { 
    const url=`${baseURL}?limit=${rowsPerPage}&offset=${page}`;
    getCall(url)
        .then(async(resp) => {
          await Promise.all(
          resp.data.map(async(order)=>{
            const res = await getCall(`/api/v1/settlements/orderSettlementStatus?orderId=${order.orderId}`);
            order.sellerSettlementStatus = res?.data?.sellerSettlementStatus || '-';
          }));
          console.log('Here is the resp->', resp);
          setOrders(resp.data);
          setTotalRecords(resp.count);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error.response);
          setLoading(false);
      });
   }

  // Get orders pertaining to that User based on the type of User
  useEffect(() => {
    setLoading(true);
    if (user && user?.role?.name === "Organization Admin") {
      let data = columns.filter((item) => item.id !== "provider_name");
      data = data.filter((item) => item.id !== "downloadAurikaInvoice");
      setColumnList(data);
      getOrders('/api/v1/orders');
    }
    if (user && user?.role?.name === "Super Admin") {
      // const url = `/api/v1/adminOrders?limit=${rowsPerPage}&offset=${page}`;
      getOrders('/api/v1/adminOrders');
    }
  }, [user,page, rowsPerPage, ]);

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("ordertablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("ordertablerowsperpage", val);
  }

  return (
    <div className="container mx-auto my-8 px-2 md:px-8">
      {loading ? loadingView : (
        <>
          <div className="mb-4 flex flex-row justify-between items-center">
            <label
              style={{ color: theme.palette.text.primary }}
              className="font-semibold text-2xl"
            >
              Orders
            </label>
          </div>
          <OrderTable
            columns={columnList}
            data={orders}
            totalRecords={totalRecords}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
        </>)}
    </div>
  );
}
